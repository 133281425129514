<div class="all-reviews-dialog p-4">
  <div mat-dialog-title class="flex">
    <div>Reviews about {{teamName()}}</div>
    <button mat-icon-button mat-dialog-close class="absolute -top-2 right-0">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="flex flex-col space-y-4 pt-2">
    <!--Review list start-->
    <ark-review-grid [reviews]="reviews()" />
    <!--Review list end-->
  </div>

  <mat-dialog-actions class="flex justify-end gap-4">
    <button
      mat-raised-button
      cdkFocusInitial
      color="accent"
      (click)="onClose()"
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
