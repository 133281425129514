import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArkDescriptiveMessageComponent } from '../descriptive-message/descriptive-message.component';
import { ArkInlineMessageAction, ArkInlineMessageComponent } from '../inline-message/inline-message.component';
import { GthMessageModel, GthUserModel } from '@sentinels/models';

@Component({
  selector: 'ark-message',
  standalone: true,
  imports: [
    ArkInlineMessageComponent,
    ArkDescriptiveMessageComponent,
  ],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class ArkMessageComponent implements OnChanges {
  @Input()
  message?: GthMessageModel;

  @Input()
  user?: GthUserModel;

  @Input()
  speaker?: GthUserModel;

  @Input()
  variant: 'inline' | 'descriptive' = 'inline';

  @Input()
  actions: ArkInlineMessageAction[] = [];

  errorLoadingImage = false;
  isMessageToday = false;

  get speakerName() {
    if (this.isCurrentUsersMessage) {
      return 'Me';
    } else {
      return this.speaker?.displayName;
    }
  }

  get userImageSrc() {
    if (this.isCurrentUsersMessage) {
      return this.user?.photoURL;
    } else {
      return this.speaker?.photoURL;
    }
  }

  get isCurrentUsersMessage() {
    if (this.message && this.user) {
      return this.message.participantId === this.user.uid;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message && this.message) {
      const today = new Date();
      const messageDate = this.message.messageSent;
      this.isMessageToday = messageDate?.getDate() == today.getDate() &&
        messageDate.getMonth() == today.getMonth() &&
        messageDate.getFullYear() == today.getFullYear();
    }
  }
}