/**
 *
 * Review service for team, group review
 *
 * */

import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ReviewOrder } from '@ark/components/reviews/reviews.component';
import { IReview } from '@index/interfaces/review';
import { DBUtil } from '@index/utils/db-utils';
import { GthReviewModel } from '@sentinels/models/review';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReviewService extends FirestoreService<GthReviewModel> {
  readonly basePath = DBUtil.Reviews;

  // Fetch reviews by team id, order by recent, oldest, endorsed, etc.
  listReviewsByTeamId$(teamId: string, orderBy: ReviewOrder = ReviewOrder.Recent) {
    let col: AngularFirestoreCollection<IReview>;

    if (orderBy === ReviewOrder.Recent) {
      col = this.firestore.collection<IReview>(this.basePath, (ref) =>
        ref.where('teamId', '==', teamId).orderBy('createdAt', 'desc'),
      );
    }

    if (orderBy === ReviewOrder.Old) {
      col = this.firestore.collection<IReview>(this.basePath, (ref) =>
        ref.where('teamId', '==', teamId).orderBy('createdAt', 'asc'),
      );
    }

    if (orderBy === ReviewOrder.Endorsed) {
      col = this.firestore.collection<IReview>(this.basePath, (ref) =>
        ref
          .where('teamId', '==', teamId)
          .orderBy('endorsed', 'desc')
          .orderBy('createdAt', 'desc'),
      );
    }

    return col.snapshotChanges().pipe(
      map((collection) => {
        return collection.map((item) => {
          const doc = item.payload.doc;
          const data = doc.data();
          return new GthReviewModel(doc.id, data);
        });
      }),
    );
  }

  // Create new review for team
  async createReview(data: Omit<IReview, 'id'>) {
    const response = await this.firestore.collection<Omit<IReview, 'id'>>(this.basePath).add({
      ...data,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
    });
    return response.id;
  }

  // Update review
  async updateReview(data: Partial<IReview>) {
    try {
      await this.firestore
        .collection<IReview>(this.basePath)
        .doc(data.id)
        .update({
          ...data,
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      return 'success';
    } catch (e) {
      return e;
    }
  }

  // Delete review
  async deleteReview(id: string) {
    try {
      await this.firestore.collection<IReview>(this.basePath).doc(id).delete();
      return 'success';
    } catch (e) {
      return e;
    }
  }
}
