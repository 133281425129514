import { Component, input, output, Input, computed , inject} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faUserGroup, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@index/interfaces';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { NgClass } from '@angular/common';
import { GthTeamModel } from '@sentinels/models';
import { ArkLogoLoadingComponent } from '@ark';


@Component({
  selector: 'ark-item-type-card',
  standalone: true,
  imports: [MatButton, FaIconComponent, NgClass, ArkLogoLoadingComponent],
  templateUrl: './item-type-card.component.html',
  styleUrl: './item-type-card.component.scss',
})
export class ArkItemTypeCardComponent {
  private router = inject(Router);
  @Input() team!: GthTeamModel;

  title = input.required<string>();
  memberCount = input.required<number>();
  buttonText = input.required<string>();
  location = input<Location>();
  typeIcon = input<IconDefinition>();
  typeLabel = input<string>();
  disabled = input<boolean>();
  photoURL = input<string>();
  
  memberText = computed(() => 
    this.memberCount() === 1 ? `${this.memberCount()} member` : `${this.memberCount()} members`)

  onClickActionButton = output<void>();
  routerLink = input<string>();

  faUserGroup = faUserGroup;
  faLocationDot = faLocationDot;

  handleClickActionButton(): void {
    this.router.navigate([this.routerLink()])
  }
}
