@if(dateRangeTypeLabel === 'Custom') {
<mat-form-field appearance="outline">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate [(ngModel)]="startDate" placeholder="Start date" (dateChange)="onDateChange()">
    <input matEndDate [(ngModel)]="endDate" placeholder="End date" (dateChange)="onDateChange()">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
}

<button mat-button [matMenuTriggerFor]="menu">
  <div class="fx-row button-row">
    <span class="range-label">{{ dateRangeTypeLabel }}</span>
    <span class="range">{{ dateRange }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
</button>

<mat-menu #menu="matMenu">
  @for(rangeType of rangeTypes; track rangeType.value) {
  <button mat-menu-item (click)="onDateRangeTypeChange(rangeType.value)">{{ rangeType.label }}</button>
  }
</mat-menu>