<div class="select fx-row fx-gap-10x">

  <mat-form-field appearance="outline">
    <mat-label>{{ label }} </mat-label>
    <mat-select [value]="_value" (selectionChange)="onOptionChange($event.value)">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{ option }}
        </mat-option>
      }
      <mat-option [value]="otherValue">
        {{ otherLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  @if(isOtherSelected) {
    <mat-form-field appearance="outline">
      <mat-label>{{ otherLabel }}</mat-label>
      <input matInput [value]="_value" (keyup)="onInputChange($event)" />
    </mat-form-field>
  }

</div>