import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormArray, FormGroup } from '@angular/forms';
import { DuprStatWidget, WidgetTag } from '@index/interfaces/widgets';
import { Store } from '@ngrx/store';
import { APP_STATE } from '@sentinels/state/state';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

import { selectUser } from '../state/features/auth/selectors';
import { WidgetsService } from './cloud/widget.service';
import { UserService } from './firebase/user.service';

const CLUB_ID = () => '7162073815';

@Injectable({
  providedIn: 'root',
})
export class DuprService {
  private functions = inject(AngularFireFunctions);
  private widgetService = inject(WidgetsService);
  private store = inject(Store<APP_STATE>);
  httpClient = inject(HttpClient);
  private user = this.store.select(selectUser);
  duprUrlBase = 'https://uat.mydupr.com/api/';
  destroyRef = inject(DestroyRef);
  duprRoute = this.duprUrlBase + 'v1.0/';
  userService = inject(UserService);
  userId = '';
  clubId = CLUB_ID();
  token: string | null = null;

  authenticate(): Observable<any> {
    this.user.pipe(
      switchMap((user) => {
        if (!user) return of(null);
        this.userId = user.id;
        return this.functions.httpsCallable('dupr-fns-authenticateAndCallDUPR')({});
      }),
      filter((data) => !!data), // stop here if no data
      switchMap(async (data) => {
        this.token = data?.results?.result?.token;

        let duprData = await this.getCurrentDuprData(this.userId);
        if (duprData) {
          const fullRoute = this.duprRoute + 'player';
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.token}`,
            }),
          };

          const body = {
            'duprIds': [
              duprData.duprId,
            ],
            'sortBy': 'string',
          };

          this.httpClient.post(fullRoute, body, httpOptions).subscribe((res) => {
            const ratings = res['results'][0]?.ratings;
            duprData = {
              ...duprData,
              doubles: ratings.doubles,
              singles: ratings.singles,
            };
            this.widgetService.update(duprData.id, duprData);
          });
        }
        return this.token;
      }),
      switchMap((token) => {
        return this.functions.httpsCallable('dupr-fns-loginWebHook')({ token });
      }),
    ).subscribe();

    return of(null);
  }

  async getCurrentDuprData(id: string): Promise<DuprStatWidget | null> {
    const widgets = await this.widgetService.readByUserId(id);
    return (widgets.find((w) => w.tag === WidgetTag.DUPR) as DuprStatWidget || null);
  }

  private createMatchEntryJSON(id: string, matchEntryForm: FormGroup): Observable<object> {
    const a = this.getTeamData(matchEntryForm, 'teamB');
    const b = this.getTeamData(matchEntryForm, 'teamA');

    return combineLatest([a, b]).pipe(map(([teamA, teamB]) => {
      return {
        bracket: 'Bracket name',
        clubId: this.clubId,
        event: this.getEventName(matchEntryForm),
        extras: this.getExtras(),
        format: this.getFormat(matchEntryForm),
        identifier: id,
        location: this.getLocation(matchEntryForm),
        matchDate: this.getMatchDate(matchEntryForm),
        matchSource: this.getMatchSource(matchEntryForm),
        matchType: 'SIDEOUT',
        teamA,
        teamB,
      };
    }));
  }

  getEventName(matchEntryForm: FormGroup): string {
    return matchEntryForm.get('eventName').value || '';
  }

  getExtras(): object {
    return {
      key1: 'value1',
      key2: 'value2',
    };
  }

  getFormat(matchEntryForm: FormGroup): string {
    return matchEntryForm.get('numPlayersPerTeam').value === 1 ? 'SINGLES' : 'DOUBLES';
  }

  getLocation(matchEntryForm: FormGroup): string {
    return matchEntryForm.get('location').value || '';
  }

  getMatchDate(matchEntryForm: FormGroup): string {
    return matchEntryForm.get('date').value || '';
  }

  getMatchSource(matchEntryForm: FormGroup): string {
    return matchEntryForm.get('matchSource')?.value || 'CLUB';
  }

  getTeamData(matchEntryForm: FormGroup, teamName: 'teamA' | 'teamB'): Observable<object> {
    const players = this.getPlayers(matchEntryForm, teamName);
    return this.userService.getUsers(players).pipe(map((users) => {
      const games = this.getGames(matchEntryForm, teamName);
      const data = {
        ...(games[0] && { game1: games[0] }),
        ...(games[1] && { game1: games[1] }),
        ...(games[2] && { game1: games[2] }),
        ...(games[3] && { game1: games[3] }),
        ...(games[4] && { game1: games[4] }),
        ...(games[4] && { game1: games[4] }),
      };
      users.map((user, index) => {
        data[`player${index + 1}`] = user.duprId || 'YGNDPE';
      });
      return data;
    }));
  }

  getPlayers(matchEntryForm: FormGroup, teamName: 'teamA' | 'teamB'): string[] {
    const team = matchEntryForm.get(teamName) as FormArray;
    return team.controls.map((control) => control.value?.id || '');
  }

  getGames(matchEntryForm: FormGroup, teamName: 'teamA' | 'teamB'): number[] {
    const scores =
      matchEntryForm.get('scores') as FormArray;
    return scores.controls.map((scoreControl) =>
      scoreControl.get(`${teamName}Score`)?.value || 0);
  }

  addMatchScores(id, matchData: FormGroup) {
    if (!this.token) return of(null);
    const matchCreationRoute = this.duprUrlBase + 'match/v1.0/create';
    return this.createMatchEntryJSON(id, matchData).pipe(switchMap((data) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        }),
      };

      const payload = {
        'bracket': 'Bracket name',
        'clubId': 7614955351,
        'event': 'Event name',

        'format': 'SINGLES',
        'identifier': data['id'],
        'location': 'Newport Beach, CA',
        'matchDate': '1984-04-10',
        'matchSource': 'CLUB',
        'matchType': 'SIDEOUT',
        'teamA': {
          'game1': 7,
          'game2': 11,
          'game3': 0,
          'game4': 0,
          'game5': 0,
          'player1': 'L8EW8W',
          'player2': 'O8GJV8',
        },
        'teamB': {
          'game1': 7,
          'game2': 11,
          'game3': 0,
          'game4': 0,
          'game5': 0,
          'player1': 'L8EW8W',
          'player2': 'O8GJV8',
        },
      };

      const body = { payload };
      return this.httpClient.post(matchCreationRoute, body, httpOptions).pipe(map((res) => {
        console.log(res);
        return res;
      }));
    }));
  }


  getClubPlayers() {
    return this.getToken().pipe(

    switchMap(() => {
      if (!this.token) return of(null);
      const matchCreationRoute = this.duprUrlBase + 'club/v1.0/members';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          }),
        };

        const payload = {
          'clubId': '6118738872',
        };

        return this.httpClient
        .post(matchCreationRoute, payload, httpOptions)
        .pipe(map((res) => {
          return res;
        }));
    }),
    );
  }

  getToken() {
    return this.user.pipe(
      switchMap((user) => {
        if (!user) return of(null);
        this.userId = user.id;
        return this.functions.httpsCallable('dupr-fns-authenticateAndCallDUPR')({});
      }),
      filter((data) => !!data), // stop here if no data
      switchMap(async (data) => {
        this.token = data?.results?.result?.token;
        return this.token;
      }));
  }
}
