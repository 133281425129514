<div class="widget">
  <div class="fx-row">
    <div class="content fx-auto">
      <h3>{{ widgetName }}</h3>
    </div>
    <br />
    @if (isSameUser()) {
      <div class="create-button">
        <a mat-fab color="accent" (click)="onAddButtonClick()">
          <mat-icon>add</mat-icon>
        </a>
      </div>
    }
  </div>
  <div class="list-containter">
    <app-match-list></app-match-list>
  </div>
</div>
