import { Component, input } from '@angular/core';
import { ArkUserAvatarComponent } from '@ark';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { GthReviewModel } from '@sentinels/models/review';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export enum ReviewGridVariant {
  All = 'all',
  Partial = 'partial',
}

@Component({
  selector: 'ark-review-grid',
  standalone: true,
  imports: [ArkUserAvatarComponent, DatePipe, MatIcon, NgxSkeletonLoaderModule],
  templateUrl: './review-grid.component.html',
  styleUrl: './review-grid.component.scss',
})
export class ReviewGridComponent {
  protected readonly ReviewGridVariant = ReviewGridVariant;

  /** Display reviews with partial comment or whole comment */
  variant = input(ReviewGridVariant.All);

  /** Reviews to display */
  reviews = input<GthReviewModel[]| null>(null);
}
