import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StorageService } from '../../services/storage.service';

const KB_TO_MB = 1024;
const MAX_FILE_SIZE_MB = 1;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * KB_TO_MB; // 1 MB

@Component({
  selector: 'gth-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GthUploadImageComponent {
  @Input()
  label = 'Upload an image';

  @Input()
  tooltip = '';

  @Input()
  accepts = ['image/*'];

  multiple = false;

  @Input()
  disabled = false;

  @Input()
  uploadPath = '';

  @Input()
  imageFormControl?: AbstractControl;

  loading = false;
  maxFileSizeMB = MAX_FILE_SIZE_MB;

  constructor(
    private snackbar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService,
  ) {}

  private async getFileDownloadURL(file: File) {
    const filePath = `${this.uploadPath}/${Date.now()}_${file.name}`;

    return await this.storageService.upload(filePath, file)
      .then(async (task) => await task.ref.getDownloadURL())
      .catch(() => {
        throw new Error(`Something went wrong uploading file`);
      });
  }

  async onFileInputChange(event: Event) {
    this.loading = true;

    const inputElement = event.target as HTMLInputElement;

    const selectedFiles = inputElement.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      this.snackbar.open('Select an image for upload.');

      return;
    }

    try {
      /** Handle one file */
      const file = selectedFiles[0];

      const fileSize = file.size / KB_TO_MB;

      if (fileSize > MAX_FILE_SIZE) {
        // eslint-disable-next-line max-len
        console.error(`File size reported at : "${fileSize}" when max is set to "${MAX_FILE_SIZE}"`);
        throw new Error(`File size exceeds limit of ${this.maxFileSizeMB}MB.`);
      }

      const downloadURL = await this.getFileDownloadURL(file);

      this.imageFormControl?.setValue(downloadURL);
    } catch (error: unknown) {
      const message = (<Error>error)?.message ||
        `Something went wrong uploading ${selectedFiles.length === 1 ? 'file' : 'files'}`;

      this.snackbar.open(message, 'OK');

      console.error('Error uploading file(s)', error);
    } finally {
      this.loading = false;

      this.cdRef.markForCheck();
    }
  }
}
