<div class="widget">
  <h3>{{widgetName}}</h3>
  <div class="ranking-row">
    <div class="ranking-item">
      <div class="ranking-item-content">
        <div class="ranking-item-title">
          <mat-icon color="accent">people</mat-icon>
          <span>Doubles</span>
        </div>
        <p class="rank">{{doublesScore}}</p>
      </div>
      <!-- <div class="spinner-container">
        <mat-progress-spinner diameter="42" color="accent" mode="determinate" [value]="doublesRating">
        </mat-progress-spinner>
      </div> -->
    </div>
    <div class="ranking-item">
      <div class="ranking-item-content">
        <div class="ranking-item-title">
          <mat-icon color="accent">person</mat-icon>
          <span>Singles</span>
        </div>
        <p class="rank">{{singlesScore}}</p>
      </div>
      <!-- <div class="spinner-container">
        <mat-progress-spinner diameter="42" color="accent" mode="determinate" [value]="singlesRating">
        </mat-progress-spinner>
      </div> -->
    </div>
  </div>
  <!-- <p class="total-matches">Total matches completed: {{matchesCompleted}}</p> -->
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="actions">
    <a  
      target="_blank"
      mat-raised-button 
      class="uppercase" 
      [href] = "duprDashboardBaseUrl + getMainId()"
      color="primary">View DUPR Profile</a>
  </div>
</div>