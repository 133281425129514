import { inject, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatchEvent } from '@index/interfaces/match-entry';
import { GthMatchEntryModel } from '@sentinels/models/team-match-entry';
import { combineLatest, map, Observable } from 'rxjs';

import { FirestoreService } from '../core/firebase.service';
interface MatchEntryWithId extends MatchEvent {
  id:string;
}

@Injectable({
  providedIn: 'root',
})
export class MatchEntryService extends FirestoreService<MatchEvent> {
  protected basePath: string = 'match-entry';
  private functions = inject(AngularFireFunctions);

  geBytEventId(eventId: string): Observable<GthMatchEntryModel[]> {
    const matches = this.firestore.collection(this.basePath, (ref) =>
      ref.where('eventId', '==', eventId))
      .valueChanges({ idField: 'id' })as unknown as Observable<MatchEntryWithId[]>;

   return matches.pipe(map((results) => {
      return results.map((result) => {
        return new GthMatchEntryModel(result.id, result);
      });
    }));
  }

  getByPlayerId(playerId: string): Observable<GthMatchEntryModel[]> {
    interface MatchEntryWithId extends MatchEvent {
      id:string;
    }

    const teamAQuery = this.firestore.collection(this.basePath, (ref) =>
      ref.where('teamA.players', 'array-contains', playerId))
      .valueChanges({ idField: 'id' })as unknown as Observable<MatchEntryWithId[]>;


    const teamBQuery = this.firestore.collection(this.basePath, (ref) =>
      ref.where('teamB.players', 'array-contains', playerId))
      .valueChanges() as unknown as Observable<MatchEntryWithId[]>;

    return combineLatest([teamAQuery, teamBQuery]).pipe(
      map(([teamAResults, teamBResults]) => [...teamAResults, ...teamBResults]),
    ).pipe(map((results) => {
      return results.map((result) => {
        return new GthMatchEntryModel(result.id, result);
      });
    }));
  }
}
