import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ark-teams-empty-state',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './teams-empty-state.component.html',
  styleUrl: './teams-empty-state.component.scss'
})
export class ArkTeamsEmptyStateComponent {
  @Input()
  discoverUri = '';

  @Input()
  createUri = '';

  @Input()
  stateTitle = 'Your teams will show here';
}
