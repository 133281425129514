import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ArkProgressiveFormLayoutItem,
  ArkProgressiveFormLayoutPage,
  IArkProgressiveFormControlService,
} from '../types';
import { ArkProgressiveFormLayoutItemComponent } from '../progressive-form-layout-item/progressive-form-layout-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormGroup } from '@angular/forms';
import { GthUploadImageModule } from '../../../../../../gth-legacy/src/public-api';

@Component({
  selector: 'ark-progressive-form-layout-page',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    ArkProgressiveFormLayoutItemComponent,
    GthUploadImageModule,
  ],
  templateUrl: './progressive-form-layout-page.component.html',
  styleUrl: './progressive-form-layout-page.component.scss',
})
export class ArkProgressiveFormLayoutPageComponent {
  @Input()
  page?: ArkProgressiveFormLayoutPage;

  @Input()
  service?: IArkProgressiveFormControlService;

  @Input()
  pageCount = 0;

  @Input()
  pageIndex = 0;

  @Input()
  isLastPage = false;

  @Input()
  formGroup: FormGroup;

  @Output()
  next = new EventEmitter<void>();

  @Output()
  previous = new EventEmitter<void>();

  get title() {
    return this.page?.title;
  }

  get formControls() {
    return this.page?.collection ?? [];
  }

  get photoFormControl() {
    return this.formGroup.get('photoURL');
  }

  get completeText() {
    return this.service?.completeText ?? 'Save';
  }

  onValueChange(key: any, val: any) {
    this.service.setPropValue(key, val);
  }

  getControlValue(control: ArkProgressiveFormLayoutItem) {
    const ctrl = this.formGroup.get(control.key);
    if (!ctrl) return '';
    return ctrl.getRawValue();
  }

  onComplete() {
    if (!this.service?.onComplete) return;
    this.service.onComplete();
  }
}
