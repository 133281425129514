import { Component, computed, inject, input, model, output } from '@angular/core';
import { ArkUserAvatarComponent } from '@ark';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReviewDialogComponent } from '@ark/components/reviews/components/review-dialog/review-dialog.component';
import { AllReviewsDialogComponent } from '@ark/components/reviews/components/all-reviews-dialog/all-reviews-dialog.component';
import { GthReviewModel } from '@sentinels/models/review';
import { GthUserModel } from '@sentinels/models';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import {
  ReviewGridComponent,
  ReviewGridVariant,
} from '@ark/components/reviews/components/review-grid/review-grid.component';

export enum ReviewOrder {
  Recent = 'Most recent',
  Old = 'Oldest',
  Endorsed = 'Endorsed',
}

@Component({
  selector: 'ark-reviews',
  standalone: true,
  imports: [
    ArkUserAvatarComponent,
    MatButton,
    MatCard,
    MatDivider,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    FormsModule,
    DatePipe,
    MatIcon,
    ReviewGridComponent,
  ],
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss',
})
export class ReviewsComponent {
  protected readonly ReviewGridVariant = ReviewGridVariant;
  private dialog = inject(MatDialog);

  // Inputs and output
  /** team's id for reviews */
  teamId = input('');
  /** Team name */
  teamName = input('');
  /** user info */
  user = input<GthUserModel>();
  /** reviews for <teamId> */
  reviews = input<GthReviewModel[]>([]);
  /** visible count of reviews */
  showCount = input(6);
  /** order review by highest, most recent, oldest */
  reviewOrderBy = model(ReviewOrder.Recent);

  /** Emitter when ordering review */
  onClickReviewOrder = output<ReviewOrder>();

  /** total endorsements for team <teamId> */
  endorsements = computed(() => {
    return this.reviews().filter((review) => review.endorsed).length;
  });

  reviewOrders = Object.values(ReviewOrder).map((order) => ({ label: order, value: order }));

  onChangeReviewOrder() {
    this.onClickReviewOrder.emit(this.reviewOrderBy());
  }

  onShowReviewDialog() {
    this.dialog.open(ReviewDialogComponent, {
      width: '450px',
      height: 'auto',
      data: {
        teamId: this.teamId(),
        user: this.user(),
      },
      backdropClass: 'gth-overlay-backdrop',
    });
  }

  onClickShowAll() {
    this.dialog.open(AllReviewsDialogComponent, {
      width: '1280px',
      height: 'auto',
      data: {
        reviews: this.reviews(),
        teamName: this.teamName(),
      },
      backdropClass: 'gth-overlay-backdrop',
    });
  }
}
