import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ark-team-uploads-empty-state',
  standalone: true,
  imports: [
    MatButtonModule,
  ],
  templateUrl: './team-uploads-empty-state.component.html',
  styleUrl: './team-uploads-empty-state.component.scss'
})
export class ArkTeamUploadsEmptyStateComponent {
  @Output()
  action = new EventEmitter<void>();

  onActionButtonClick() {
    this.action.emit();
  }
}
