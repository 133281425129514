import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from "@angular/platform-browser";
import { DuprData } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { BehaviorSubject, map } from 'rxjs';
import { SrvSafeWindowService } from '../../../../../../sentinels/src/lib/services/safe-window.service';
import { UserService } from '@sentinels/services/firebase/user.service';

const clientId = 'dGVzdC1jay1hMzliNjRiOC0xOGFiLTRiZWUtZjlmZi1hYjU1NWFjODZjZDc=';
// const url = 'https://uat.dupr.gg/login-external-app/' + clientId;
const url = 'https://mydupr.com/login-external-app/' + btoa(clientId);

interface DuprLoginDialogContract {
  user: GthUserModel;
}

const durData = new BehaviorSubject<DuprData | null>(null);

@Component({
  selector: 'app-dupr-login-dialog',
  templateUrl: './dupr-login-dialog.component.html',
  styleUrls: ['./dupr-login-dialog.component.scss']
})
export class DuprLoginDialogComponent {
  public dialogRef = inject(MatDialogRef<DuprLoginDialogComponent>);
  public sanitizer = inject(DomSanitizer);
  url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  data: DuprLoginDialogContract = inject(MAT_DIALOG_DATA)
  durData = durData;
  srvSafeWindowService = inject(SrvSafeWindowService);

  constructor() {
    if (!this.srvSafeWindowService.hasWindow) return;

    window.addEventListener('message', DuprLoginDialogComponent.handleMessage, false);

    this.durData.pipe(map((duprData) => {
      if (!duprData) null;
      return duprData;
    })).subscribe((duprData) => {
      if (duprData) this.dialogRef.close(duprData);
    });
  }

  static handleMessage(event) {
    const data = event.data;
    if (!data) return;

    const token = data.userToken;
    const doubles = data.stats?.doubles;
    const singles = data.stats?.singles;
    const id = data.id;
    const duprId = data.duprId;
    const duprEmail = data.email;

    if (!token || !doubles || !singles) return;

    const duprData: DuprData = {
      token,
      doubles,
      singles,
      mainId: id,
      duprId,
      duprEmail,
    }
    durData.next(duprData);
    return duprData;
  }
}


