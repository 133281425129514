<div class="dialog">
  <div mat-dialog-title class="dialog-title">
    <h1>Add a New Widget</h1>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="content fx-column">
    <form [formGroup]="myGroup">
      <mat-form-field appearance="outline">
        <mat-label>Widget Name</mat-label>
        <input matInput formControlName="widgetName" #nameInput />
        <!-- @if(widgetName?.length) {
        <button mat-icon-button matSuffix (click)="nameInput.value = ''">
          <mat-icon>clear</mat-icon>
        </button>
        } -->
      </mat-form-field>
    </form>
    <div class="fx-auto widget-list">
      @for (widget of widgets(); track widget) {
        <div class="widget">
          <div class="widget-title">
            @if (widget.prefixIcon) {
              <mat-icon color="accent">{{ widget.prefixIcon }}</mat-icon>
            }
            <span class="title">{{ widget.name }}</span>
          </div>
          <p>{{ widget.description }}</p>
          <div class="widget-actions fx-row">
            <button
              [disabled]="installedWidgetsTagSet().has(widget.tag)"
              mat-raised-button
              color="accent"
              (click)="onAddWidgetButtonClick(widget)"
            >
              Add Widget to Profile
            </button>
            <div class="spacer"></div>
            <div>
              <img [src]="WIDGET_ICON_URL.get(widget.icon)" [alt]="widget.icon + '-logo'">
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
