import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GthMessageModel, GthUserModel } from '@sentinels/models';

export type ArkInlineMessageAction = {
  label: string;
  icon: string;
  action: (message: GthMessageModel) => void;
  displayedIf: (message: GthMessageModel, user: GthUserModel, speaker: GthUserModel) => boolean;
}

@Component({
  selector: 'ark-inline-message',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  templateUrl: './inline-message.component.html',
  styleUrl: './inline-message.component.scss'
})
export class ArkInlineMessageComponent implements OnInit {
  @Input()
  message?: GthMessageModel;

  @Input()
  user?: GthUserModel;

  @Input()
  speaker?: GthUserModel;

  @Input()
  speakerName = '';

  @Input()
  userImageSrc = '';

  @Input()
  isMessageToday = false;

  @Input()
  isCurrentUsersMessage = false;

  @Input()
  actions: ArkInlineMessageAction[] = [];

  errorLoadingImage = false;
  displayedActions: ArkInlineMessageAction[] = [];

  ngOnInit(): void {
    if(!this.user || !this.message) return;
    this.displayedActions = this.actions.filter((action) => action.displayedIf(this.message, this.user, this.speaker));
  }
}
