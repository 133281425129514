<app-dashboard-card [headerText]="title()">
  <span slot="body">
    <!-- @if (buttonText() || title()) {
      <div class="flex items-center justify-between mb-4">
        <h2 class="my-0">{{ title() }}</h2>
        @if (buttonText()){
          <div>
            <button (click)="handleClickActionButton()" mat-raised-button color="accent">
              {{ buttonText() }}
            </button>
          </div>
        }
      </div>
    } -->

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{label()}}</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="searchText"
        (ngModelChange)="handleChangeSearchText($event)"
      />
    </mat-form-field>

    <div class="relative">
      @if (isDisabled()) {
        <div class="disabled-container">
          <div>
            @if (disabledIcon()) {
              <div class="text-lg">
                <fa-icon [icon]="disabledIcon()"></fa-icon>
              </div>
            }
            @if (disabledText()) {
              <div>
                <strong>{{ disabledText() }}</strong>
              </div>
            }
          </div>
        </div>
      }
      <ng-content></ng-content>
    </div>
  </span>
</app-dashboard-card>
