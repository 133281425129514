<button mat-button [matMenuTriggerFor]="menu">
  <div class="fx-row button-row">
    <span>{{ value ?? label }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
</button>

<mat-menu #menu="matMenu">
  @for(option of options; track option) {
  <button mat-menu-item (click)="valueChange.emit(option)">{{ option }}</button>
  }
</mat-menu>