import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

export type ArkCheckboxCardsOption = {
  key: string;
  label: string;
  description?: string;
  withIconEnd?: string;
};

export type ArkCheckboxCardChange = {
  source: ArkCheckboxCardsComponent;
  value: string[];
};

@Component({
  selector: 'ark-checkbox-cards',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ArkCheckboxCardsComponent
    }
  ],
  templateUrl: './checkbox-cards.component.html',
  styleUrl: './checkbox-cards.component.scss'
})
export class ArkCheckboxCardsComponent implements ControlValueAccessor, OnChanges {
  /**
   * Array of selected 'keys'
   */
  @Input()
  value: Array<string> = [];

  @Input()
  options?: ArkCheckboxCardsOption[];

  @Output()
  change = new EventEmitter<ArkCheckboxCardChange>();

  onChange = (keys: Array<string>) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const newValue = changes.value.currentValue;
      this.value = newValue;
    }
  }

  isChecked(key: string) {
    return this.value?.includes(key);
  }

  onCheckboxGroupChange(key: string, checked: boolean) {
    let newValue = [...this.value];
    if (!newValue) newValue = [];

    let change = false;
    if (checked) {
      if (!this.isChecked(key)) {
        newValue.push(key);
        change = true;
      }
    } else {
      const index = newValue.indexOf(key);
      if (index === -1) return;
      newValue.splice(index, 1);
      change = true;
    }

    if (!change) return;
    this.markAsTouched();
    this.value = newValue;
    this.onChange(newValue);
    this.change.emit({
      source: this,
      value: newValue,
    });
  }

  // ControlValueAccessor Functions

  writeValue(obj: Array<string> | undefined): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
