import { Component, DestroyRef, inject, input, model, OnInit, output, signal } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AlgoliaService } from '@shared/layouts/nav-bar-layout/components/omni-search/services/algolia.service';
import { DashboardCardComponent } from '../../../../../gth/src/app/features/teams-dashboard/shared/dashboard-card/dashboard-card.component';

@Component({
  selector: 'ark-auto-complete-search-card',
  standalone: true,
  imports: [MatButton, MatFormField, MatInput, MatLabel, FormsModule, FaIconComponent, DashboardCardComponent],
  templateUrl: './auto-complete-search-card.component.html',
  styleUrl: './auto-complete-search-card.component.scss',
})
export class ArkAutoCompleteSearchCardComponent implements OnInit {
  // normal/model inputs, outputs
  title = input<string>();
  buttonText = input<string>();
  label = input('Search');
  disabledIcon = input<IconDefinition>();
  disabledText = input<string>();
  isDisabled = input<boolean>();

  searchText = model('');

  onClickActionButton = output<void>();
  onChangeSearchText = output<string>();

  private algoliaService = inject(AlgoliaService);
  destroyRef = inject(DestroyRef);

  searchResults = signal([]);
  searchTextChangeSubject = new Subject<string>();

  ngOnInit() {
    this.searchTextChangeSubject
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.onChangeSearchText.emit(value);
        this.fetchSearchResults(value);
      });
  }

  handleClickActionButton(): void {
    this.onClickActionButton.emit();
  }

  handleChangeSearchText(value: string): void {
    this.searchText.set(value);
    this.searchTextChangeSubject.next(value);
  }

  private fetchSearchResults(query: string): void {
    this.algoliaService.search(query, true).subscribe((results) => {
      const communityResults = results.find((result) => result.name === 'Communities');
      this.searchResults.set(communityResults?.hits || []);
    });
  }

  clearSearch(): void {
    this.searchText.set('');
    this.searchResults.set([]);
  }
}
