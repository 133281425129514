export type Widget = {
  id?: string;
  name: string;
  tag: WidgetTag;

  createdBy?: string;
  createdOn?: Date;
  lastModifiedBy?: string;
  lastModifiedOn?: Date;
};

export type DuprStatWidget = Widget & {
  tag: WidgetTag.DUPR;
  singles?: number | string;
  doubles?: number | string;
  mainId?: string | number;
  duprId?: string;
  duprEmail?: string;
};

export type ScoreTrackerWidget = Widget & {
  tag: WidgetTag.ScoreTracker;
  activityType: string;
}

export enum WidgetTag {
  DUPR = 'dupr_stat',
  ScoreTracker = 'score_tracker',
}
