import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Location } from '@index/interfaces/location';
import { geohashForLocation } from 'geofire-common';
import { lastValueFrom } from 'rxjs';

import { AppReverseGeocodeService } from '../../services/reverse-geocode.service';
import { GthGoogleMapModule } from '../google-map/google-map.module';

export interface AddressSelectionDialogContract {
  address: Location;
}
export interface AddressSelectionDialogCloseContract extends Location {}
export interface AddressSelectionFormGroup {
  placeResult: FormControl<{name: string, formatted_address?: string}>,
  lat: FormControl<number>,
  lng: FormControl<number>,
}

@Component({
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    GthGoogleMapModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxGpAutocompleteModule,
  ],
})
export class AddressSelectionComponent {
  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  options = {
    componentRestrictions: {
      country: ['US'],
    },
  };
  zoom = 16;
  locationForm = new FormGroup<AddressSelectionFormGroup>({
    placeResult: new FormControl(
      { name: '', formatted_address: '' },
      { nonNullable: true, validators: Validators.required },
    ),
    lat: new FormControl(
      null,
      { nonNullable: true, validators: Validators.required },
    ),
    lng: new FormControl(
      null,
      { nonNullable: true, validators: Validators.required },
    ),
  });
  placeInputOptions: any = {
    types: ['address'],
    componentRestrictions: { country: 'US' },
  };
  private readonly originalValue?: Location;
  private dirty = false;

  constructor(
    private geocode: AppReverseGeocodeService,
    private dialogRef: MatDialogRef<AddressSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AddressSelectionDialogContract,
  ) {
    const location = this.data?.address;
    if (location) {
      this.originalValue = { ...location };
      this.locationForm.controls.placeResult.setValue(
        { name: this.data.address.formattedAddress },
      );
      this.locationForm.controls.lat.setValue(location.lat);
      this.locationForm.controls.lng.setValue(location.lng);
    }
  }

  /**
   * Returns true if the address form is valid
   */
  get isFormValid() {
    return this.locationForm.valid;
  }

  get lat() {
    return this.locationForm.controls.lat.value;
  }

  get lng() {
    return this.locationForm.controls.lng.value;
  }

  get placeResult() {
    return this.locationForm.controls.placeResult.value;
  }

  onSaveButtonClick() {
    if (!this.locationForm.dirty && !this.dirty) {
      return this.dialogRef.close(this.originalValue);
    }

    const locationFormValue = this.locationForm.getRawValue();

    const location: AddressSelectionDialogCloseContract = {
      lat: locationFormValue.lat,
      lng: locationFormValue.lng,
      formattedAddress: locationFormValue.placeResult.formatted_address,
      hash: geohashForLocation([locationFormValue.lat, locationFormValue.lng]),
    };
    return this.dialogRef.close(location);
  }

  onCancelButtonClick() {
    if (!this.originalValue) {
      this.dialogRef.close(undefined);
      return;
    }

    this.dialogRef.close({
      lat: this.originalValue.lat,
      lng: this.originalValue.lng,
      formattedAddress: this.originalValue.formattedAddress,
      hash: null,
    });
  }

  clearCityInput() {
    this.locationForm.reset();
  }

  onAutocompleteSelected(place: google.maps.places.PlaceResult) {
    if (!place.geometry || !place.geometry.location) {
      return;
    }
    const location = place.geometry.location;
    this.locationForm.controls.placeResult.setValue({
      name: place.name,
      formatted_address: place.formatted_address,
    });
    this.locationForm.controls.lat.setValue(location.lat());
    this.locationForm.controls.lng.setValue(location.lng());
    this.dirty = true;
  }

  async markerDragEnd(event: google.maps.MapMouseEvent) {
    const lat = event.latLng!.lat();
    const lng = event.latLng!.lng();
    const address = await lastValueFrom(this.geocode.getReverseGeocode$(lat, lng));
    if (!address) {
      return;
    }

    this.locationForm.controls.placeResult.setValue(
      {
        name: address.formattedAddress,
        formatted_address: address.formattedAddress,
      });
    this.locationForm.controls.lat.setValue(address.lat);
    this.locationForm.controls.lng.setValue(address.lng);
    this.dirty = true;
  }
}
