<div class="checkbox-group">
  @for (option of options; track option) {
  <div class="checkbox-button" [class.--active]="isChecked(option.key)" [class.--no-description]="!option.description">
    <mat-checkbox [checked]="isChecked(option.key)" (change)="onCheckboxGroupChange(option.key, $event.checked)">
      <div class="checkbox-button-content">
        <p class="--label">
          {{ option.label }}
        </p>
        @if(option.description) {
        <p class="--description"> {{ option.description }}</p>
        }
      </div>

      @if(option.withIconEnd) {
      <mat-icon class="end-icon">{{ option.withIconEnd }}</mat-icon>
      }
    </mat-checkbox>
  </div>
  }
</div>