import { inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { eventLoadOne } from '@sentinels/state/public-api';
import { APP_ROUTES } from '@shared/helpers';
import { algoliasearch, SearchResult } from 'algoliasearch';
import { debounceTime, from, map, Observable, of, Subject, timer } from 'rxjs';

import { APP_STATE } from '../../../../../../../../../gth-legacy/src/public-api';

const appID = 'GKZUM0ATNK';
// todo(ark): Replace the below with the
// actual Algolia search interface needed for the search results
interface AlgoliaSearchResult {
  hits: SearchResult<any>[];
  index: string;
  query: string;
}

export interface OnmiSearchType {
  name: string;
  link: (id: string) => string;
  hits: any[];
  title: (item: any) => string;
}

const apiKey = 'a10d053f8d9bc0d71a202ea5d36ed605';
const eventsIndexName = 'events-algolia';
const teamsIndexName = 'teams-algolia';
const usersIndexName = 'users-algolia';
const placesIndexName = 'places-algolia';

const eventsLink = (id: string) => `${APP_ROUTES.Event}/${id}`;
const teamsLink = (id: string) => `${APP_ROUTES.Team}/${id}`;
// todo(Kevin) Add Places Once fixed ;)
const placesLink = (id: string) => `${APP_ROUTES.Place}/${id}`;
const usersLink = (id: string) => `${APP_ROUTES.Profile}/${id}`;

const eventName = (item) => item.title;
const teamsName = (item) => item.name;
const usersName = (item) => item.displayName || item.name;
const placesName = (item) => item.name;

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  store = inject(Store<APP_STATE>);
  snakbar = inject(MatSnackBar);
  client = algoliasearch(appID, apiKey);
  private searchCallSubject = new Subject<void>();
  private callCounter = 0;
  locked = false;

  constructor(private ngZone: NgZone) {
    this.client.clearCache();

    this.ngZone.runOutsideAngular(() => {
      this.searchCallSubject
        .pipe(debounceTime(500))
        .subscribe(() => this.ngZone.run(() => this.incrementCallCounter()));
    });

    this.ngZone.runOutsideAngular(() => {
      timer(0, 1000).subscribe(() => {
        this.ngZone.run(() => this.decrementCallCounter());
      });
    });
  }

  private incrementCallCounter() {
    this.callCounter++;
    if (this.callCounter > 5) {
      this.locked = true;
      this.callCounter = 10;
      this.snakbar.open(
        'Search is currently disabled due to too many calls in quick succession',
        'Close',
      );
    }
  }

  private decrementCallCounter() {
    if (this.callCounter > 0) {
      this.callCounter--;
      if (this.callCounter === 0 && this.locked) {
        this.locked = false;
        this.snakbar.open('Search is now available', 'Close');
      }
    }
  }

  search(query: string, emptySearch = false, limit = false): Observable<OnmiSearchType[]> {
    if ((!query && !emptySearch) || this.locked) return of([]);
    this.searchCallSubject.next();
    return from(
      this.client.search({
        requests: [
          {
            indexName: eventsIndexName,
            query,
          },
          {
            indexName: usersIndexName,
            query,
          },
          {
            indexName: teamsIndexName,
            query,
          },
          {
            indexName: placesIndexName,
            query,
          },
        ],
      }),
    ).pipe(
      map((data) => {
        return data.results;
      }),
      map((results) => {
        return this.transformData(results as unknown as AlgoliaSearchResult[]);
      }),
    );
  }

  transformData(data: AlgoliaSearchResult[]) {
    return data
      .filter((item) => item.hits.length > 0)
      .map((item) => {
        return this.getSearchTypeFormat(item.index, item.hits);
      });
  }

  getSearchTypeFormat<T>(indexName, items: any[]): OnmiSearchType {
    switch (indexName) {
      case eventsIndexName:
        const eventModels = items
          .map((item) => {
            return new GthEventItemModel(item.objectID, item);
          })
          .filter((item) => {
            return !item.inPast && !item.cancelled;
          })
          .map((item) => {
            this.store.dispatch(eventLoadOne({ id: item.id }));
            return item;
          })
          .slice(0, 20);

        return this.searchObjectBuilder('Events', eventsLink, eventName, eventModels);
      case teamsIndexName:
        const teamsModels = items
          .map((item) => {
            return new GthTeamModel(item.id, item);
          })
          .slice(0, 20);
        return this.searchObjectBuilder('Communities', teamsLink, teamsName, teamsModels);
      case placesIndexName:
        return this.searchObjectBuilder('Places', placesLink, placesName, items.slice(0, 20));
      case usersIndexName:
        const userModels = items
          .map((item) => {
            return new GthUserModel(item.objectID, item);
          })
          .filter((item) => {
            return item.displayName || item.fullName;
          })
          .slice(0, 20);

        return this.searchObjectBuilder('Users', usersLink, usersName, userModels);
      default:
        throw Error('Invalid index name');
    }
  }

  searchObjectBuilder(
    name: string,
    link: (id: string) => string,
    title: (item: any) => string,
    hits: any[],
  ): OnmiSearchType {
    const searchObject = {
      name,
      link,
      title,
      hits,
    };
    return searchObject;
  }
}
