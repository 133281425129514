import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ArkProgressiveFormLayoutPage, IArkProgressiveFormControlService } from '../types';
import { Subscription } from 'rxjs';
import { ArkLoadingProgressService } from '../../../services/progress.service';
import { ArkProgressiveFormLayoutPageComponent } from '../progressive-form-layout-page/progressive-form-layout-page.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ark-progressive-form-layout',
  standalone: true,
  imports: [
    CommonModule,
    ArkProgressiveFormLayoutPageComponent,
  ],
  templateUrl: './progressive-form-layout.component.html',
  styleUrl: './progressive-form-layout.component.scss'
})
export class ArkProgressiveFormLayoutComponent implements OnChanges, OnDestroy {
  @Input()
  service?: IArkProgressiveFormControlService;

  get pages() {
    return this.service?.layout?.pages;
  }

  private subscription = new Subscription();
  activePage?: ArkProgressiveFormLayoutPage;
  pageIndex = 0;
  pageCount = 0;
  isLastPage = false;

  constructor(private progress: ArkLoadingProgressService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service) this.initFormService();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onNextButtonClick() {
    if (!this.service) return;
    if (this.service.invalid) return;
    this.pageIndex = this.service.next();
    this.setPage();
  }

  onBackButtonClick() {
    if (!this.service) return;
    this.pageIndex = this.service.previous();
    this.setPage();
  }

  private setPage() {
    if(!this.service?.layout) return;
    this.activePage = this.service.layout?.pages[this.pageIndex];
    this.isLastPage = this.service.layout?.pages.length - 1 === this.pageIndex;
  }

  private initFormService() {
    this.activePage = undefined;
    this.isLastPage = true;
    this.pageIndex = 0;
    this.pageCount = 0;
    if (!this.service) return;

    this.setPage();
    this.pageCount = this.service.layout.pages.length;

    this.subscription.unsubscribe();

    this.subscription.add(
      this.service.progress.subscribe((progress) => {
        this.progress.progress = progress;
      }),
    );
  }
}
