<div class="container">
  <mat-card class="card">
    <h2>Enter Match Details</h2>
    <form [formGroup]="matchEntryForm" class="form">
      <mat-form-field appearance="fill" class="full-length-field">
        <mat-label>Event Name</mat-label>
        <input matInput formControlName="eventName">
      </mat-form-field>

      <mat-card class="location-card">
        @if(!matchEntryForm.get('location').value) {
          <h2>No Location has been Entered.</h2>
        } @else {
          <h2>{{matchEntryForm.get('location').value}}</h2>
        }
        <mat-form-field appearance="fill" class="full-length-field invisible">
          <mat-label>Location</mat-label>
          <input 
            matInput 
            formControlName="location" placeholder="Location" readonly>
        </mat-form-field>
        <button mat-raised-button (click)="onAddressButtonClick()" class="location-button">
          Select Location
        </button>
      </mat-card>

      <mat-form-field appearance="fill" class="full-length-field">
        <mat-label>Date</mat-label>
        <input matInput formControlName="date" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-length-field">
        <mat-label>Activity Type</mat-label>
        <mat-select
          
          formControlName="activityType">
          <mat-option
            *ngFor="let type of gameTypes"
            [value]="type.label">{{type.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="bottom-container">
        <mat-form-field appearance="fill">
          <mat-label>Number of Players per Team</mat-label>
          <input 
            type="number"
            min="1"
            max="50"
            #playerCountInput
            matInput type="number" 
            formControlName="numPlayersPerTeam"
            (change)="onPlayerCountChange(playerCountInput.value)">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Number of Games</mat-label>
          <input 
            matInput 
            min="1"
            max="7"
            type="number" 
            formControlName="numGames">
        </mat-form-field>

        <mat-checkbox 
          #addYourselfCb
          (change) ="onAddYouToTeamOneChange(addYourselfCb.checked)"
          formControlName="addYouToTeamOne">
          Add yourself as a participant
        </mat-checkbox>

        @if(matchEntryForm.get('activityType')?.value === 'Pickleball') {
          <mat-checkbox formControlName="sendToDupr">
            Send to DUPR
            <mat-icon matSuffix matTooltip="Sending results to DUPR for official ranking updates.">info</mat-icon>
          </mat-checkbox>
        }
      </div>
      <!-- Players handling -->
      <mat-card class="teams-card">
        <div formArrayName="teamA" class="team-container">
          <div>
            <h2>Team A</h2>
          </div>
          <div *ngFor="let player of getPlayersControls('teamA'); let i = index" [formGroupName]="i">
            <mat-form-field class="example-full-width">
              <mat-label>Assignee</mat-label>
              <input type="text" matInput [formControl]="player" [matAutocomplete]="auto" (keyup)="onPlayerSearch($event)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="createDisplayFn">
                @for (option of (searchResultsArray | async); track option) {
                  
                  <mat-option [value]="option" >
                    <mat-list>
                      <mat-list-item>
                        <span matListItemTitle>{{option?.displayName}}</span>
                        <span matListItemLine>{{option?.defaultCity?.name}}</span>
                      </mat-list-item>
                    </mat-list>
                  </mat-option>
                } 
              </mat-autocomplete>
              <mat-error *ngIf="player.hasError('noDuprId')">
                This player does not have a DUPR ID.
              </mat-error>
              <mat-error *ngIf="player.hasError('required')">
                This field is required.
              </mat-error>
              <mat-error *ngIf="player.hasError('notGthUserModel')">
                This player is not a valid user.
              </mat-error>
            </mat-form-field>
            <button 
              class="remove-button"
              mat-raised-button  
              color="primary"
              (click)="removePlayer('teamA', i)">Remove Player</button>
          </div>
        </div>
        <mat-divider class="teams-divider"></mat-divider>

        <div formArrayName="teamB" class="team-container">
          <div>
            <h2>Team B</h2>
          </div>
          <div *ngFor="let player of getPlayersControls('teamB'); let i = index" [formGroupName]="i">
            <mat-form-field class="example-full-width">
              <mat-label>Assignee</mat-label>
              <input type="text" matInput [formControl]="player" [matAutocomplete]="auto" (keyup)="onPlayerSearch($event)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="createDisplayFn">
                @for (option of (searchResultsArray | async); track option) {
                  
                  <mat-option [value]="option">
                    <mat-list>
                      <mat-list-item>
                        <span matListItemTitle>{{option?.displayName}}</span>
                        <span matListItemLine>{{option?.defaultCity?.name}}</span>
                      </mat-list-item>
                    </mat-list>
                  </mat-option>
   
                } 
              </mat-autocomplete>

            </mat-form-field>
            <button
              class="remove-button"
              mat-raised-button  
              color="primary"
              (click)="removePlayer('teamA', i)">Remove Player</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="team-score-card">
        <h2>Team Scores</h2>
        <mat-list role="score-list" *ngIf="scores.controls.length > 0">
          <div *ngFor="let score of scores.controls; let i = index">
            <mat-list-item>
              <mat-form-field>
                <mat-label>Team A Score</mat-label>
                <input matInput [formControl]="getScoreInput(score, 'teamAScore')"placeholder="Score A" />
              </mat-form-field>
              <span class="vs-text">vs</span>
              <mat-form-field>
                <mat-label>Team B Score</mat-label>
                <input matInput [formControl]="getScoreInput(score, 'teamBScore')" placeholder="Score B" />
              </mat-form-field>
            </mat-list-item>
            <mat-divider *ngIf="i < scores.controls.length - 1"></mat-divider>
          </div>
        </mat-list>
      </mat-card>
      <button 
        mat-raised-button 
        [disabled]="!isFormValid.isValid"
        (click)="onSubmit()"
        color="primary" 
        type="submit">Submit Matches</button>
    </form>
  </mat-card>
</div>
