<ark-link-card [width]="width()" [routerLink]="cardLink()" [queryParams]="queryParams()">
  @defer (when !!team()) {
    <picture class="banner">
      <img class="banner-image" [src]="bannerImg()" [height]="150" priority>
      <img class="banner-underlay" [src]="bannerImg()" priority>
    </picture>
    <div class="content">
      <div class="upper-content">
        <div class="team-info">
          <h3>{{ team().name }}</h3>
          <p>
            <mat-icon>{{ teamSportIcon() }}</mat-icon>
            <span>{{ team().sport }}</span>
          </p>
        </div>
        <p>
          <mat-icon>location_on</mat-icon>
          <span>{{ team().location?.formattedAddress }}</span>
        </p>
      </div>

      <div class="lower-content">
        <a mat-flat-button color="primary">
          View Team
        </a>
        <!-- <p>
          <mat-icon>people</mat-icon>
          <span>{{ team().roster?.length }}</span>
        </p> -->
      </div>
    </div>
  }
</ark-link-card>
