import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatChip, MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { GthUserModel } from '@sentinels/models';
import { GthMatchEntryModel } from '@sentinels/models/team-match-entry';
import { UserService } from '@sentinels/services/firebase/user.service';
import { ParticipantCollectionComponent } from '@shared/components/participant-collection/participant-collection.component';
import { map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-match-view',
  templateUrl: './match-view.component.html',
  styleUrls: ['./match-view.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ParticipantCollectionComponent,
    MatExpansionModule,
    MatChipsModule,
    DatePipe,
    MatIconModule,
    MatGridListModule,
    MatListModule,
  ],
})
export class MatchViewComponent implements OnInit {
  usersService = inject(UserService);
  match = input<GthMatchEntryModel | null>(null);

  match$ = toObservable(this.match);

  teamAGthUsers$ = this.match$.pipe(
    switchMap((match) => {
      if (!match?.teamA?.players) return of([]);

      return this.usersService.getUsers(match?.teamA?.players);
    }));

  teamBGthUsers$ = this.match$.pipe(switchMap((match) => {
    if (!match?.teamB?.players) return of([]);

    return this.usersService.getUsers(match?.teamB?.players);
  }));

  teamAGames$ = this.match$.pipe(map((match) => {
    if (!match) return [];
    return match.teamA.games.sort((a, b) => a.gameNumber - b.gameNumber);
  }));

  teamBGames$ = this.match$.pipe(map((match) => {
    if (!match) return [];
    return match.teamB.games.sort((a, b) => a.gameNumber - b.gameNumber);
  }));

  teamAGthUsers = toSignal<GthUserModel[]>(this.teamAGthUsers$);
  teamBGthUsers = toSignal<GthUserModel[]>(this.teamBGthUsers$);
  teamAGames = toSignal(this.teamAGames$);
  teamBGames = toSignal(this.teamBGames$);

  constructor() {

  }

  ngOnInit() {

  }

}
