import { Component, computed, input, signal } from '@angular/core';
import { ArkLinkCardComponent } from '../link-card/link-card.component';
import { RouterModule } from '@angular/router';
import { GthTeamModel } from '@sentinels/models';
import { MatButtonModule } from '@angular/material/button';
import { APP_ROUTES } from '@shared/helpers';
import { MatIconModule } from '@angular/material/icon';
import { DefaultEventIcon, EventTypeIcons } from '@index/enums';

const DEFAULT_IMG = 'assets/Gametime-Hero_Twitter-Card_800x418.png';

@Component({
  selector: 'ark-team-card',
  standalone: true,
  imports: [
    ArkLinkCardComponent,
    MatButtonModule,
    RouterModule,
    MatIconModule,
  ],
  templateUrl: './team-card.component.html',
  styleUrl: './team-card.component.scss',
})
export class ArkTeamCardComponent {
  team = input.required<GthTeamModel>();
  width = input<string | undefined>(undefined);

  public routes = APP_ROUTES;
  cardLink = computed(() =>
    [this.routes.Team, this.team().id],
  );

  queryParams = signal({ context: 'communities' });

  bannerImg = computed(() =>
    this.team().photoURL || DEFAULT_IMG,
  );

  teamSportIcon = computed(() =>
    EventTypeIcons[this.team().sport] ?? DefaultEventIcon,
  );
}
