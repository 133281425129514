<div class="layout-page fx-column fx-gap-10x">
  <p>Step {{ pageIndex + 1 }} of {{ pageCount }}</p>

  @if(pageIndex > 0) {
  <div>
    <button mat-button (click)="previous.emit()">
      <mat-icon>arrow_back</mat-icon>
      Back
    </button>
  </div>
  }

  <h2>{{ title }}</h2>

  @for(control of formControls; track control) {
  <ark-progressive-form-layout-item [control]="control" [value]="getControlValue(control)"
    (valueChange)="onValueChange(control.key, $event)" />
  }

  <div>
    @if(isLastPage) {

    <!-- TODO Move this-->
    <gth-upload-image uploadPath="teams" [imageFormControl]="photoFormControl" />

    <button mat-raised-button color="accent" (click)="onComplete()" [disabled]="!formGroup.valid">{{ completeText }}</button>
    } @else {
    <button mat-raised-button color="accent" (click)="next.emit()" [disabled]="!formGroup.valid">Next Step</button>
    }
  </div>
</div>
