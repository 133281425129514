<swiper-container appSwiper init="false" [config]="config">
  @for (item of items(); track item) {
    <swiper-slide>
      <ark-item-type-card
        class="card-item"
        [title]="item.title"
        [memberCount]="item.memberCount"
        [buttonText]="item.buttonText"
        [location]="item.location"
        [typeLabel]="item.typeLabel"
        [typeIcon]="item.typeIcon"
        [routerLink]="item.link"
        [photoURL]="item.photoURL"
      />
    </swiper-slide>
  }

</swiper-container>

@if (items().length) {
  <div class="controls">
    <button mat-mini-fab color="primary" class="carousel-button-prev">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <button mat-mini-fab color="primary" class="carousel-button-next">
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
}

