import { MatchEvent, TeamMatchData } from '@index/interfaces/match-entry';
import { Timestamp } from 'firebase/firestore';

import { GthModel } from './model';

export class GthMatchEntryModel extends GthModel<MatchEvent> {
  constructor(id: string, model: MatchEvent) {
    super(id, model);
  }

  get id(): string {
    return this._id;
  }

  get clubId(): number {
    return this.model.clubId;
  }

  get event(): string {
    return this.model.event;
  }

  get eventId(): string {
    return this.model.eventId;
  }

  get location(): string {
    return this.model.location;
  }

  get matchDate(): Date {
    if (this.model.matchDate instanceof Timestamp) {
      return this.model.matchDate.toDate();
    }
    return this.model.matchDate;
  }

  get matchSource(): string {
    return this.model.matchSource;
  }

  get activity(): string {
    return this.model.activity;
  }

  // Getter and setter for teamA
  get teamA(): TeamMatchData {
    return this.model.teamA;
  }

  set teamA(team: TeamMatchData) {
    this.model.teamA = team;
  }

  // Getter and setter for teamB
  get teamB(): TeamMatchData {
    return this.model.teamB;
  }

  set teamB(team: TeamMatchData) {
    this.model.teamB = team;
  }

  // Fetch scores for Team A
  get teamAGameScores() {
    return this.model.teamA.games;
  }

  // Fetch scores for Team B
  get teamBGameScores() {
    return this.model.teamB.games;
  }

  // Utility to get all players from team A
  get teamAPlayers(): string[] {
    return this.extractPlayers(this.teamA);
  }

  // Utility to get all players from team B
  get teamBPlayers(): string[] {
    return this.extractPlayers(this.teamB);
  }

  get winningTeam(): 'teamA' | 'teamB' | 'draw' | 'unknown' {
    let teamAWins = this.teamAGameScores.filter((game) => game.score > 0).length;
    let teamBWins = this.teamBGameScores.filter((game) => game.score > 0).length;

    if (this.teamA.games.length !== this.teamB.games.length) return 'unknown';

    for (let i = 0; i < this.teamA.games.length; i++) {
      if (this.teamAGameScores[i].score > this.teamBGameScores[i].score) {
        teamAWins++;
      } else if (this.teamAGameScores[i].score < this.teamBGameScores[i].score) {
        teamBWins++;
      }
    }

    if (teamAWins === teamBWins) return 'draw';
    else if (teamAWins > teamBWins) return 'teamA';
    else return 'teamB';
  }

  // Extract players utility function
  private extractPlayers(team: TeamMatchData): string[] {
    return Object.keys(team)
      .filter((key) => key.startsWith('player') && team[key])
      .map((key) => team[key] as string);
  }

  //   // Set game scores for Team A
  //   setTeamAGameScores(scores: GameScore[]) {
  //     this.model.teamA.games = scores;
  //   }

  //   // Set game scores for Team B
  //   setTeamBGameScores(scores: GameScore[]) {
  //     this.model.teamB.games = scores;
  //   }

  // Example method to update match information
  updateMatchData(
    event: string,
    location: string,
    matchDate: Date,
    matchSource: string,
  ) {
    this.model.event = event;
    this.model.location = location;
    this.model.matchDate = matchDate;
    this.model.matchSource = matchSource;
  }

  isValidMatch(): boolean {
    return !!this.model.event && !!this.model.location && !!this.model.matchDate;
  }
}
