import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

const OTHER_VALUE = () => '$OTHER$';

@Component({
  selector: 'ark-custom-select',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './custom-select.component.html',
  styleUrl: './custom-select.component.scss'
})
export class ArkCustomSelectComponent implements OnChanges {
  @Input()
  label = '';

  @Input()
  otherLabel = '';

  @Input()
  options: string[] = [];

  @Input()
  value = null;

  @Output()
  valueChange = new EventEmitter<string>();

  _value = '';
  readonly otherValue = OTHER_VALUE();

  get isOtherSelected() {
    return this._value === this.otherValue || !this.options.includes(this._value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const val = changes.value.currentValue;
      this.onOptionChange(val);
    }
  }

  onOptionChange(val: string) {
    if(val === OTHER_VALUE()) {
      this.value = OTHER_VALUE();
      this._value = '';
    } else if(!this.options.includes(val)) {
      this.value = OTHER_VALUE();
      this._value = val;
    } else {
      this.value = val;
      this._value = val;
    }

    this.valueChange.emit(this._value);
  }

  onInputChange(val: KeyboardEvent) {
    const newValue = (val.target as any).value
    this.value = OTHER_VALUE();
    this._value = newValue;
    this.valueChange.emit(this._value);
  }
}
