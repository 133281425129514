import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';

export type ArkRadioCardsOption = {
  key: string | boolean;
  label: string;
  description?: string;
  withIconEnd?: string;
  disabled?: boolean;
  linkText?: string;
  linkUrl?: string;
};

export type ArkRadioCardChange = {
  source: ArkRadioCardsComponent;
  value: string | boolean | undefined;
};

@Component({
  selector: 'ark-radio-cards',
  standalone: true,
  imports: [
    MatRadioModule,
    MatIconModule,
    JsonPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ArkRadioCardsComponent
    }
  ],
  templateUrl: './radio-cards.component.html',
  styleUrl: './radio-cards.component.scss'
})
export class ArkRadioCardsComponent implements ControlValueAccessor, OnChanges {
  /**
   * Array of selected 'keys'
   */
  @Input()
  value: string | boolean | undefined;

  @Input()
  options?: ArkRadioCardsOption[];

  @Output()
  change = new EventEmitter<ArkRadioCardChange>();

  onChange = (keys: Array<string> | Array<boolean>) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['value']) {
      const newValue = changes.value.currentValue;
      this.value = newValue;
    }
  }

  onRadioGroupChange(change: MatRadioChange) {
    const newValue = change.value;
    this.markAsTouched();
    this.value = newValue;
    this.onChange(newValue);
    this.change.emit({
      source: this,
      value: newValue,
    });
  }

  // ControlValueAccessor Functions

  writeValue(obj: string | boolean | undefined): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
