import { Component, Inject, OnInit, computed, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DuprStatWidget, ScoreTrackerWidget, Widget, WidgetTag } from '@index/interfaces/widgets';
import { GthGameTypeModel, GthUserModel } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';
import { WidgetsService, WidgetType } from '@sentinels/services/cloud/widget.service';
import { WidgetConfigDialogComponent } from '../widget-config-dialog/widget-config-dialog.component';
import { of, startWith, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { WIDGET_ICON_URL } from '@ark/components/widgets/data';
export type ArkWidgetDialogContract = {
  user: GthUserModel;
}

@Component({
  selector: 'ark-widget-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatDialogModule,
    WidgetConfigDialogComponent,
  ],
  templateUrl: './widget-dialog.component.html',
  styleUrl: './widget-dialog.component.scss'
})
export class ArkWidgetDialogComponent implements OnInit {
  WIDGET_ICON_URL = WIDGET_ICON_URL;
  private widgetService: WidgetsService = inject(WidgetsService);
  allWidgets = toSignal(this.widgetService.getWidgetTypes$());
  widgetName = new FormControl('')
  myGroup = new FormGroup({
    widgetName: this.widgetName,
  });
  widgets$ = this.widgetName.valueChanges.pipe(
    startWith(''),
    switchMap((val) =>{

   const allWidgets = this.allWidgets();
    if(!val) return of(allWidgets);
    const filtered = allWidgets.filter((w) => w.name.toLowerCase().includes(val.toLowerCase()));
    return of(filtered);

  }));

  widgets = toSignal(this.widgets$);
  gameTypes: GthGameTypeModel[] = [];
  allUserWidgets= toSignal(this.widgetService.readByUserId$(this.data.user.uid));
  installedWidgetsTagSet =computed(() => new Set(this.allUserWidgets()?.map((w) => w.tag)));

  constructor(
    public dialogRef: MatDialogRef<ArkWidgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArkWidgetDialogContract,

    private dialog: MatDialog,
    private api: SrvApiService,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<void> {
    this.gameTypes = await this.api.gameTypes.listAsync();
  }

  async onAddWidgetButtonClick(widgetType: WidgetType) {
    const user = this.data.user;
    if (!user) return;

    const widget: Widget = {
      name: widgetType.name,
      tag: widgetType.tag,
      createdBy: user.uid,
      createdOn: new Date(),
    };
    if (!widgetType.config) {
      this.createWidget(widget);
      return;
    }

    // Open dialog to configure widget
    const dialog = this.dialog.open(WidgetConfigDialogComponent, {
      height: '400px',
      width: '400px',
      data: {
        widgetType,
        gameTypes: this.gameTypes,
        user,
      },
    });
    dialog.afterClosed().subscribe((config) => {
      if (!config) {
        this.snackbar.open('Configuration is required for this widget');
        return;
      }
      switch (widgetType.tag) {
        case WidgetTag.ScoreTracker:
          const scoreWidget = widget as ScoreTrackerWidget;
          scoreWidget.activityType = config.activity_type;
          this.createWidget(scoreWidget);
          break;

        case WidgetTag.DUPR:
          const duprWidget = widget as DuprStatWidget;
          duprWidget.singles = config.singles;
          duprWidget.doubles = config.doubles;
          duprWidget.duprEmail = config.duprEmail;
          duprWidget.duprId = config.duprId;
          duprWidget.mainId = config.mainId;
          this.createWidget(duprWidget);
          break;
      }
    });
  }

  private async createWidget(widget: Widget) {
    const success = await this.widgetService.create(widget);
    if (success) {
      this.snackbar.open('Successfully added a widget to your profile');
    } else {
      this.snackbar.open('Error adding a widget to your profile');
    }
  }
}
