import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { APP_STATE } from '@gth-legacy';
import { EventsService } from '@gth-legacy/services/events.service';
import { DefaultEventIcon, EventTypeIcons } from '@index/enums';
import { ScoreTrackerWidget, Widget } from '@index/interfaces/widgets';
import { Store } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { MatchEntryComponent } from '@shared/components/match-entry/match-entry.component';
import { APP_ROUTES } from '@shared/helpers';
import { combineLatest, first, map, switchMap } from 'rxjs';
import { MatchEntryService } from '@sentinels/services/firebase/match-entry.service';
import { MatchListComponent } from '@ark/components/match-list/match-list.component';
import { toObservable } from '@angular/core/rxjs-interop';


@Component({
  selector: 'ark-score-tracker-widget',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatchEntryComponent,
    MatchListComponent,
  ],
  templateUrl: './score-tracker-widget.component.html',
  styleUrl: './score-tracker-widget.component.scss'
})
export class ArkScoreTrackerWidgetComponent implements OnInit {
  widget = input<Widget>();

  private store = inject(Store<APP_STATE>);
  private eventsService = inject(EventsService);
  private dialog = inject(MatDialog);
  isSameUser = input<boolean>(true);

  get ROUTES() {
    return APP_ROUTES;
  }

  get widgetName() {
    const widget = this.widget();
    return widget?.name ?? 'Match History';
  }

  activityType = '';
  activityTypeIcon = computed(() => EventTypeIcons[this.activityType] ?? DefaultEventIcon);
  events: GthEventItemModel[] = [];
  user = this.store.selectSignal(selectUser);
  router = inject(ActivatedRoute);

  async ngOnInit(): Promise<void> {
    this.router.params.pipe(map((params) => {
      const id =  params['id'];
    }));
    const scoreTrackerWidget = this.widget() as ScoreTrackerWidget;
    if(!scoreTrackerWidget) return;
    this.activityType = scoreTrackerWidget.activityType;
    const user = this.user();
    if(!user) return;
    this.events = await this.eventsService.getEvents$(user.uid)
      .pipe(
        first(),
        map((events) => events.filter((e) => {
          e.gameType === this.activityType
        })),
      )
      .toPromise();
  }

  onAddButtonClick() {
    this.dialog.open(MatchEntryComponent, {
      height: '800px',
    })
  }
}
