import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Widget, WidgetTag } from '@index/interfaces/widgets';
import { lastValueFrom, map, of } from 'rxjs';

import { FirestoreService } from '../core/firebase.service';

export type WidgetType = {
  name: string;
  description: string;
  prefixIcon?: string;
  icon: string;
  tag: WidgetTag;
  config?: WidgetConfiguration;
};

export type WidgetConfiguration = {
  description: string;
  properties: WidgetConfigurationItemType[];
};

export enum WidgetConfigurationItemType {
  ActivityType = 'activity_type',
  DUPR = 'dupr',
}

const PREDEFINED_WIDGETS: WidgetType[] = [
  {
    name: 'Dupr Integration',
    description: `Dupr is the industry leader in pickleball ratings.
    Connect your Dupr account to display your ranking on your profile,
    track your matches, and more!`,
    icon: 'dupr',
    tag: WidgetTag.DUPR,
    config: {
      description: `To integrate your Dupr account with Gametime Hero, please sign in with your
      Dupr account information.`,
      properties: [WidgetConfigurationItemType.DUPR],
    },
  },
  {
    name: 'Score Tracker',
    description: 'Display and add your match history on your profile.',
    icon: 'gth',
    prefixIcon: 'edit_document',
    tag: WidgetTag.ScoreTracker,
    config: {
      description: `To add this widget to your profile, please input your desired activity
      to track matches for.`,
      properties: [WidgetConfigurationItemType.ActivityType],
    },
  },
];

@Injectable({
  providedIn: 'root',
})
export class WidgetsService extends FirestoreService<Widget> {
  protected basePath = 'user_widgets';

  constructor(firestore: AngularFirestore) {
    super(firestore);
  }

  getWidgetTypes$() {
    return of(PREDEFINED_WIDGETS);
  }

  getWidgetTypes() {
    return Promise.resolve(PREDEFINED_WIDGETS);
  }

  async readByUserId(id: string): Promise<Widget[]> {
    const widgetRefs = await lastValueFrom(this.firestore
      .collection(this.basePath, (ref) => ref.where('createdBy', '==', id))
      .get());

    if (widgetRefs.empty) return [];

    return widgetRefs.docs.map((w) => {
      const id = w.id;
      return {
        id,
        ...(w.data() as object),
      } as Widget;
    });
  }

  readByUserId$(id: string) {
    return this.firestore
      .collection<Widget>(this.basePath, (ref) => ref.where('createdBy', '==', id))
      .valueChanges({ idField: 'id' })
      .pipe(
        map((docs) => {
          return docs.map((w) => {
            const id = w.id;
            return {
              id,
              ...w,
            } as Widget;
          });
        }),
      );
  }

  async createPredefinedWidget(userId: string, widgetTag: WidgetTag) {
    const widgetType = PREDEFINED_WIDGETS.find((w) => w.tag === widgetTag);
    return this.firestore.collection(this.basePath).doc(userId).set(widgetType);
  }
}
