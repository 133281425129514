import { Component, OnInit, inject, input } from '@angular/core';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatchViewComponent } from './components/match-view/match-view.component';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatchEntryListService } from './services/match-entry.service';

@Component({
  selector: 'app-match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.scss'],
  standalone: true,
  imports: [ 
  MatExpansionModule,
  MatchViewComponent,
  MatButtonModule,
  AsyncPipe,
  ],
})
export class MatchListComponent implements OnInit {
  eventId = input<string| null>(null);

  matchEntryListService = inject(MatchEntryListService);

  ngOnInit() {
    this.matchEntryListService.eventId = this.eventId();
  }


  get limitedMatches$ (){
    return this.matchEntryListService.limitedMatches$;
  }

  get canViewMore$ (){ 
    return this.matchEntryListService.canViewMore$;
  }
 
  onShowMore() {
    this.matchEntryListService.onShowMore();
  }

}
