/**
 *
 * Review model for team, group
 *
 * */
import { IReview } from '@index/interfaces/review';
import { GthModel } from '@sentinels/models/model';

export class GthReviewModel extends GthModel<IReview> {
  constructor(id: string, model: IReview) {
    super(id, model);
  }

  get id() {
    return this._id;
  }

  get teamId() {
    return this.model.teamId;
  }

  get user() {
    return this.model.user;
  }

  get updatedAt() {
    return this.model.updatedAt.toDate();
  }

  get createdAt() {
    return this.model.createdAt.toDate();
  }

  get endorsed() {
    return this.model.endorsed;
  }

  get comment() {
    return this.model.comment;
  }

  get approved() {
    return this.model.approved;
  }

  set approved(val) {
    this.model.approved = val;
  }

  get reportedAsInAppropriate() {
    return this.model.reportedAsInAppropriate;
  }
}
