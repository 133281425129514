import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DuprStatWidget, Widget } from '@index/interfaces/widgets';
import { GthUserModel } from '@sentinels/models';

@Component({
  selector: 'ark-dupr-widget',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './dupr-widget.component.html',
  styleUrl: './dupr-widget.component.scss'
})
export class ArkDuprWidgetComponent {
  duprDashboardBaseUrl = 'https://dashboard.dupr.com/dashboard/player/';

  widget = input.required<Widget>();
  user = input<GthUserModel>();
  isSameUser = input<boolean>(true);
  get widgetName() {
    const widget = this.widget();
    return widget?.name ?? 'Ranking';
  }

  get duprWidget(){
    return this.widget() as DuprStatWidget;
  }

  get id(){
    return this.widget().id
  }

  get singlesScore() {
    return this.duprWidget.singles || 'NR';
  }

  get doublesScore() {
    return this.duprWidget.doubles || 'NR';
  }

  get singlesRating() {
    return 12;
  }

  get doublesRating() {
    return 15;
  }

  get matchesCompleted() {
    return 35
  }

  getMainId() {
    return this.duprWidget.mainId;
  }
}
