import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ark-team-upcoming-events-empty-state',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './team-upcoming-events-empty-state.component.html',
  styleUrl: './team-upcoming-events-empty-state.component.scss'
})
export class ArkTeamUpcomingEventsEmptyStateComponent {
  @Input()
  teamId = '';

  @Input()
  createUri = '';
}
