import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressSelectionComponent } from '@gth-legacy/components/address-selection/address-selection.component';
import { Location } from '@index/interfaces';
import { MatchEvent, TeamMatchData } from '@index/interfaces/match-entry';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { GthMatchEntryModel } from '@sentinels/models/team-match-entry';
import { SrvApiService } from '@sentinels/services/api.service';
import { DuprService } from '@sentinels/services/dupr.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { AlgoliaService } from '@shared/layouts/nav-bar-layout/components/omni-search/services/algolia.service';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import { MatchEntryListService } from '../../../../../../ark/src/lib/components/match-list/services/match-entry.service';
import { APP_STATE } from '../../../../../../gth-legacy/src/public-api';
import { MatchEntryFormComponent } from './components/match-entry-form/match-entry-form.component';

AlgoliaService;
@Component({
  selector: 'app-match-entry',
  templateUrl: './match-entry.component.html',
  styleUrls: ['./match-entry.component.scss'],
  standalone: true,
  imports: [
  MatchEntryFormComponent,
  ],
})
export class MatchEntryComponent {
  store = inject(Store<APP_STATE>);
  matchEntryListService = inject(MatchEntryListService);
  user = this.store.selectSignal(selectUser);
  dialogRef = inject(DialogRef);

  onSubmit() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

