<div class="widget">
  @if (isWidgetSet()) {
    <div class="widget-title-row">
      <div>
        <img [src]="WIDGET_ICON_URL.get(widgetType().icon)" [alt]="widgetType().icon + '-logo'">
      </div>
      <div class="spacer"></div>
      @if (isSameUser()) {
        <button mat-icon-button (click)="edit.emit()">
          <mat-icon>edit</mat-icon>
        </button>
      }
    </div>
    @if (widget().tag === WidgetTag.DUPR) {
      <ark-dupr-widget
        class="widget-content"
        [isSameUser]="isSameUser()"
        [user]="user()"
        [widget]="widget()"
      />
    } @else if (widget().tag === WidgetTag.ScoreTracker) {
      <ark-score-tracker-widget
        [isSameUser]="isSameUser()"
        class="widget-content"
        [widget]="widget()"
      />
    }
  }
  @if (isSameUser()) {
    <div space="controls">
      <button mat-icon-button (click)="onDelete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
</div>
