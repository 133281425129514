import { Component, input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ArkItemTypeCardComponent } from '@ark';
import { Location } from '@index/interfaces';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { SwiperDirective } from '@shared/directives/swiper/swiper.directive';
import { SwiperOptions } from 'swiper/types';

export interface ICommonCardItem {
  title: string;
  memberCount: number;
  buttonText: string;
  location?: Location;
  typeIcon?: IconDefinition;
  typeLabel?: string;
  link?: string;
  photoURL?: string;
}

@Component({
  selector: 'card-carousel',
  standalone: true,
  imports: [ArkItemTypeCardComponent, MatIcon, MatButtonModule, SwiperDirective],
  templateUrl: './card-carousel.component.html',
  styleUrl: './card-carousel.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArkCardCarouselComponent {
  
  /** array of cards */
  public items = input<ICommonCardItem[]>([]);

  public config: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: '15',
    navigation: {
      nextEl: '.carousel-button-next',
      prevEl: '.carousel-button-prev'
    },
    breakpoints: {
      300: {
       slidesPerView: 1.25,
     },
     660: {
       slidesPerView: 2.25,
     },
     960: {
      slidesPerView: 3.25,
    },
    }
  }
}
