
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="title-container">
        @for(user of teamAGthUsers()?.slice(0,1); track user?.id){
          @if(user?.photoURL){
            <img class="avatar" [src]="user.photoURL"/>
          }
        }
        @for(user of teamAGthUsers()?.slice(1,2); track user?.id){
          @if(user?.photoURL){
            <img class="avatar" [src]="user.photoURL"/>
          }
        }
        @if(teamAGthUsers()?.length > 2){
          <div class="avatar last">
            <p>{{teamAGthUsers()?.length-2}}</p>
          </div>
        }
        <span class="vs-text">VS</span>
        @for(user of teamBGthUsers()?.slice(0,1); track user?.id){
          @if(user?.photoURL){
            <img class="avatar" [src]="user.photoURL"/>
          }
        }
        @for(user of teamBGthUsers()?.slice(1,2); track user?.id){
          @if(user?.photoURL){
            <img class="avatar" [src]="user.photoURL"/>
          }
        }
        @if(teamBGthUsers()?.length > 2){
          <div class="avatar last">
            <p>{{teamAGthUsers()?.length-2}}</p>
          </div>
        }

      </div>
    </mat-panel-title>
    <mat-panel-description>
      <mat-chip>
        <p>{{match()?.matchDate | date: 'dd/MM/yyyy'}}</p>
      </mat-chip>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <mat-grid-list cols="2" rowHeight="1:1" >
    <mat-grid-tile class="tile">
      <div>
        <mat-list class="list">
          <h2 [className]="match()?.winningTeam === 'teamA' ? 'winner' : ''">Team A</h2>
          @for(user of teamAGthUsers(); track user?.id){
            <mat-list-item>
              <img matListItemIcon class="small-avatar" [src]="user?.photoURL"/>
              <p matListItemTitle>{{user?.displayName || 'Unknown'}}</p>
            </mat-list-item>
          }
        </mat-list>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div>
        <mat-list class="list">
          <h2 [className]="match()?.winningTeam === 'teamB' ? 'winner' : ''">Team B</h2>
          @for(user of teamBGthUsers(); track user?.id){
            <mat-list-item>
              @if(user?.photoURL){
                <img matListItemIcon class="small-avatar" [src]="user.photoURL"/>
              }@else {
                <div class="user-img--placeholder">
                  <mat-icon>edit</mat-icon>
                </div>
              }
              <p matListItemTitle>{{user?.displayName || 'Unknown'}}</p>
            </mat-list-item>
          }
        </mat-list>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="game-list-container">
        <mat-list class="list">
          <h2>Team A Scores</h2>
            @for(game of teamAGames(); track game.gameNumber){
              <mat-list-item>
                <p matListItemTitle><b>game {{game.gameNumber}}:</b> {{game.score}}</p>
              </mat-list-item>
            }
        </mat-list>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="game-list-container">
        <mat-list class="list">
          <h2>Team B Scores</h2>
            @for(game of teamBGames(); track game.gameNumber){
              <mat-list-item>
                <p matListItemTitle><b>game {{game.gameNumber}}:</b> {{game.score}}</p>
              </mat-list-item>
            }
        </mat-list>
      </div>
    </mat-grid-tile>
    </mat-grid-list>
</mat-expansion-panel>


