import { Component, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { GthGameTypeModel, GthUserModel } from '@sentinels/models';
import { WidgetType } from '@sentinels/services/cloud/widget.service';
import { DuprService } from '@sentinels/services/dupr.service';
import { DuprLoginDialogComponent } from '../dupr-login-dialog/dupr-login-dialog.component';
import { DuprStatWidget } from '@index/interfaces/widgets';
import { WIDGET_ICON_URL } from '@ark/components/widgets/data';
import { UserService } from '@sentinels/services/firebase/user.service';

export type ArkWidgetDialogContract = {
  widgetType: WidgetType;
  gameTypes: GthGameTypeModel[];
  user: GthUserModel;
};

@Component({
  selector: 'ark-widget-config-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './widget-config-dialog.component.html',
  styleUrl: './widget-config-dialog.component.scss',
})
export class WidgetConfigDialogComponent {
  WIDGET_ICON_URL = WIDGET_ICON_URL;

  get widget() {
    return this.data.widgetType;
  }

  get config() {
    return this.widget.config;
  }

  get properties() {
    return this.config?.properties || [];
  }

  formGroup!: FormGroup;
  duprService = inject(DuprService);
  userService = inject(UserService);
  dialog = inject(MatDialog);

  constructor(
    public dialogRef: MatDialogRef<WidgetConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArkWidgetDialogContract,
    private formBuilder: FormBuilder,
  ) {
    const properties = {};
    this.properties.forEach((prop) => {
      properties[prop] = ['', Validators.required];
    });
    this.formGroup = this.formBuilder.group(properties);
  }

  onAddWidgetButtonClick() {
    if (!this.formGroup.valid) return;
    this.dialogRef.close(this.formGroup.getRawValue());
  }

  onClose() {
    this.dialogRef.close();
  }

  onSignInWithDuprButtonClick() {
    const clientId = 'dGVzdC1jay1hMzliNjRiOC0xOGFiLTRiZWUtZjlmZi1hYjU1NWFjODZjZDc=';
    const url = 'https://uat.dupr.gg/login-external-app/' + clientId;

    const duprLoginDialog = this.dialog.open(DuprLoginDialogComponent, {
      width: '350px',
      height: '600px',
      data: {
        user: this.data.user,
      },
    });

    duprLoginDialog.afterClosed().subscribe((result) => {
      const id = this.data.user.uid;
      this.userService.update(id, { duprId: result.duprId });
      this.dialogRef.close(result);
    });
  }

  onHowToCreateDuprButtonClick() {
    const url = 'https://gametimehero.notion.site/Gametime-Hero-X-DUPR-Info-Account-Linking-Much-More-1292672335fe807085bef8f32e91131d';

    window.open(url, '_blank');
  }
}
