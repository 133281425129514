import { ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import { FormGroup, ValidatorFn } from "@angular/forms";
import { Subject } from "rxjs";

export interface IArkProgressiveFormControlService {
  layout?: ArkProgressiveFormLayout;
  progress: Subject<number>;
  activePageIndex: Subject<number>;
  invalid: boolean;
  formGroup : FormGroup;
  completeText: string;

  updateLayout: (ctrl: ArkProgressiveFormLayout) => void | Promise<void>;
  setActivePageIndex: (index: number) => void;
  next: () => number;
  previous: () => number;
  setPropValue: (key: unknown, val: unknown) => void;
  disable: () => void;
  enable: () => void;
  onComplete: () => Promise<void>;
}

export type ArkProgressiveFormLayout = {
  pages: ArkProgressiveFormLayoutPage[];
};

export type ArkProgressiveFormLayoutPage = {
  title: string;
  formKey: string;
  collection: ArkProgressiveFormLayoutItem[];
};

export type ArkProgressiveFormLayoutItem = {
  key: string;
  label: string;
  itemType: ArkProgressiveFormLayoutItemType;
  description?: string; // Used by text_input and number_input
  isBold?: boolean;
  options?: ArkProgressiveFormLayoutItemOption<any>[];
  min?: number;
  max?: number;
  validator?: ValidatorFn[];
  multiple?: boolean;
  template?: ComponentPortal<any> | TemplatePortal; // https://material.angular.io/cdk/portal/overview
};

export type ArkProgressiveFormLayoutItemOption<T> = {
  key: T;
  label: string;
  description?: string;
  withIconEnd?: string;
};

export enum ArkProgressiveFormLayoutItemType {
  title, // Non interactive text header is displayed to the user.
  text, // Non interactive text header is displayed to the user.
  divider, // Non interactive divider (mat-divider)
  text_input, // input[type=”text”]
  number_input, // input[type=”number”]
  radio_group, // mat-radio-group
  radio_group_cards, // ark-radio-cards
  radio_checkbox_cards, // ark-checkbox-cards
  single_select_chip, // ark-chip-collection
  location, // ark-location-input
  range, // ark-range-input
  checkbox, // mat-checkbox
  date, // mat-datepicker
  date_range, // mat-datepicker
  select, // mat-select
  custom, // cdk-portal
}
