import { Component, computed, inject, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ReviewDialogComponent } from '@ark/components/reviews/components/review-dialog/review-dialog.component';
import { GthReviewModel } from '@sentinels/models/review';
import { ArkUserAvatarComponent } from '@ark';
import { MatIcon } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  ReviewGridComponent,
  ReviewGridVariant,
} from '@ark/components/reviews/components/review-grid/review-grid.component';

@Component({
  selector: 'ark-all-reviews-dialog',
  standalone: true,
  imports: [
    ArkUserAvatarComponent,
    MatIcon,
    DatePipe,
    MatDialogTitle,
    MatIconButton,
    MatDialogClose,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    ReviewGridComponent,
  ],
  templateUrl: './all-reviews-dialog.component.html',
  styleUrl: './all-reviews-dialog.component.scss',
})
export class AllReviewsDialogComponent {
  readonly snackbar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ReviewDialogComponent>);
  readonly data = inject(MAT_DIALOG_DATA);

  reviews = computed<GthReviewModel[]>(() => this.data.reviews);
  teamName = computed(() => this.data.teamName);

  onClose() {
    this.dialogRef.close();
  }

  protected readonly ReviewGridVariant = ReviewGridVariant;
}
