@if(control) {
@switch (control.itemType) {
@case (ArkProgressiveFormLayoutItemType.title) {
<h2>{{ control.label }}</h2>
}
@case (ArkProgressiveFormLayoutItemType.text) {
<p [class.--bold]="control.isBold">{{ control.label }}</p>
}
@case (ArkProgressiveFormLayoutItemType.divider) {
<mat-divider></mat-divider>
}
@case (ArkProgressiveFormLayoutItemType.text_input) {
<mat-form-field appearance="outline">
  <mat-label>{{ control.label }}</mat-label>
  <input 
    matInput 
    [disabled]="disabled" 
    [placeholder]="control.description" 
    [value]="value ?? ''"
    (keyup)="onInputValueChange($event)">
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.number_input) {
<mat-form-field appearance="outline">
  <mat-label>{{ control.label }}</mat-label>
  <input type="number" matInput [disabled]="disabled" [placeholder]="control.description" [value]="value ?? ''"
    (keyup)="onInputValueChange($event)">
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.radio_group) {
<div class="labeled-group">
  <label [id]="control.key">{{ control.label }}</label>
  <mat-radio-group 
    [attr.aria-labelledby]="control.key" 
    [disabled]="disabled" 
    [value]="value"
    (change)="onSetValueChange($event.value)">
    @for(option of control.options; track option) {
    <mat-radio-button class="labeled-option" [value]="option.key">{{option.label}}</mat-radio-button>
    }
  </mat-radio-group>
</div>
}
@case (ArkProgressiveFormLayoutItemType.radio_group_cards) {
<ark-radio-cards 
  [value]="value" 
  [options]="control.options" 
  (change)="onSetValueChange($event.value)" />
}
@case (ArkProgressiveFormLayoutItemType.radio_checkbox_cards) {
  <ark-checkbox-cards 
    [value]="value" 
    [options]="control.options" 
    (change)="onSetValueChange($event.value)" />
  }
@case (ArkProgressiveFormLayoutItemType.single_select_chip) {
<div class="labeled-group">
  <label [id]="control.key">{{ control.label }}</label>
  <mat-chip-listbox [attr.aria-labelledby]="control.key" [disabled]="disabled" [value]="value"
    (change)="onSetValueChange($event.value)" [multiple]="control.multiple">
    @for(option of control.options; track option) {
    <mat-chip-option class="chip-option" [value]="option.key">{{option.label}}</mat-chip-option>
    }
  </mat-chip-listbox>
</div>
}
@case (ArkProgressiveFormLayoutItemType.location) {
<mat-form-field appearance="outline">
  <mat-label>{{ control.label }}</mat-label>
  <input matInput #cityInput ngx-gp-autocomplete (change)="clearCityInput()" [value]="value.name ?? ''"
    #placesRef="ngx-places" [options]="options" (onAddressChange)="onAutocompleteSelected($event)" />
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.range) {
<ark-age-range [key]="control.key" [value]="value" (valueChange)="onAgeRangeChange($event)"/>
}
@case (ArkProgressiveFormLayoutItemType.checkbox) {
<mat-checkbox [checked]="value" (change)="onSetValueChange($event.checked)" [disabled]="disabled">{{ control.label
  }}</mat-checkbox>
}
@case (ArkProgressiveFormLayoutItemType.date) {
<mat-form-field appearance="outline">
  <mat-label>{{ control.label }}</mat-label>
  <input matInput [matDatepicker]="picker" [disabled]="disabled" (dateChange)="onSetValueChange($event.value)">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.date_range) {
<mat-form-field appearance="outline">
  <mat-label>{{control.label }}</mat-label>
  <mat-date-range-input [formGroup]="dateRange" [rangePicker]="rangePicker">
    <input matStartDate formControlName="start" [disabled]="disabled" placeholder="Start date">
    <input matEndDate formControlName="end" [disabled]="disabled" placeholder="End date">
  </mat-date-range-input>
  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #rangePicker></mat-date-range-picker>

  @if (dateRange.controls.start.hasError('matStartDateInvalid')) {
  <mat-error>Invalid start date</mat-error>
  }
  @if (dateRange.controls.end.hasError('matEndDateInvalid')) {
  <mat-error>Invalid end date</mat-error>
  }
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.select) {
<mat-form-field appearance="outline">
  <mat-label>{{ control.label }}</mat-label>
  <mat-select [value]="value" [disabled]="disabled" (selectionChange)="onSetValueChange($event.value)"
    [multiple]="control.multiple">
    @for(option of control.options; track option) {
    <mat-option [value]="option.key">{{option.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
}
@case (ArkProgressiveFormLayoutItemType.custom) {
}
}
}