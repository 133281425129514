import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, catchError, map, of, retry, shareReplay, switchMap, take, timeout, timer } from 'rxjs';
import { APP_STATE} from '../../../../../gth-legacy/src/public-api';
import { Store } from '@ngrx/store';
import { DefaultCity } from '@index/interfaces';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { Weather } from '@index/models/weather';
import { GuestUserLocationService } from '@shared/services/guest-user-location.service';
import { LocationService } from '../../../../../gth/src/app/features/discover/services/location.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export const enum GoogleLoadedStatus {
  success,
  fail,
}

export const defaultLocationImage = '/assets/img/location-default.svg'

@Injectable({
  providedIn: 'root',
})
export class LocationCardService {
  private http = inject(HttpClient);
  private locationService = inject(LocationService);
  private store = inject(Store<APP_STATE>);
  private CACHE_COLLECTION = 'locationImages';
  private firestore = inject(AngularFirestore);
  user = this.store.selectSignal(selectUser);
  guestUserLocationService = inject(GuestUserLocationService);
  private weatherCache: { [cityName: string]: Observable<Weather> } = {};

  public getWeather$(cityName: string): Observable<Weather> {
    if (!this.weatherCache[cityName]) {
      const url = `https://api.weatherapi.com/v1/current.json`;

      const options = {
        params: new HttpParams().set('q', cityName).set('key', environment.weatherApi),
      };

      this.weatherCache[cityName] = this.http.get(url, options).pipe(
        timeout(5000),
        retry(3),
        map((response: any) => {
          return {
            temp: Math.round(response.current?.temp_f),
            condition: response.current?.condition.text,
          };
        }),
        catchError((error) => {
          console.error(error);
          return of({ temp: null, condition: null })
        }),
        shareReplay(1),
      );
    }

    return this.weatherCache[cityName];
  }


  public getLocationImage$(name: string, lat: number, lng: number): Observable<string> {
    const cacheKey = name.trim().toLowerCase().replace(/\s+/g, '_'); // Normalize name for consistent keys

    // Check the cache in Firestore
    return this.firestore
      .collection(this.CACHE_COLLECTION)
      .doc(cacheKey)
      .get()
      .pipe(
        take(1),
        switchMap((docSnapshot) => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data() as { imageUrl: string };
            return of(data.imageUrl);
          } else {
            // If not cached, fetch it from Google Maps API
            return this.googleMapsLoader$().pipe(
              take(1),
              map((status) => {
                if (status !== GoogleLoadedStatus.success) {
                  throw new Error('Google Maps PlacesService not available.');
                }
              }),
              switchMap(() => this.searchPlace$(name, lat, lng)), // Fetch the image based on name
              switchMap((imageUrl) => {
                // Save the fetched image URL in Firestore
                return this.firestore
                  .collection(this.CACHE_COLLECTION)
                  .doc(cacheKey)
                  .set({ imageUrl })
                  .then(() => imageUrl);
              }),
              catchError((error) => {
                console.error(error);
                return of(defaultLocationImage); // Return default image on error
              })
            );
          }
        }),
        catchError((error) => {
          console.error('Error accessing Firestore cache:', error);
          return of(defaultLocationImage); // Return default image on Firestore error
        })
      );
  }

  public changeLocation(city: DefaultCity, isGuestUser = false) {
    this.locationService.setLocation(city.lat, city.lng, city.name);
  }

  private googleMapsLoader$(): Observable<GoogleLoadedStatus> {
    return new Observable((observer) => {
      if (typeof google?.maps?.places?.PlacesService !== 'undefined') {
        observer.next(GoogleLoadedStatus.success);
        observer.complete();
      }

      const startTime = new Date().getTime();
      const maxWaitTime = 7000; // 7 seconds in milliseconds

      function checkExistence() {
        if (typeof google?.maps?.places?.PlacesService !== 'undefined') {
          observer.next(GoogleLoadedStatus.success);
          observer.complete();
        } else {
          const currentTime = new Date().getTime();
          if (currentTime - startTime >= maxWaitTime) {
            observer.next(GoogleLoadedStatus.success);
            observer.complete();
            // observer.error(new Error('Timeout: PlacesService not available within 7 seconds.'));
          } else {
            setTimeout(checkExistence, 100); // Check again after 100 milliseconds
          }
        }
      }

      checkExistence();
    });
  }

  private searchPlace$(name: string, lat: number, lng: number): Observable<string> {
    return new Observable((observer) => {
      const request = {
        location: { lat, lng },
        radius: 1000,
        keyword: name,
      };

      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.nearbySearch(request, (results, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          observer.error(new Error('Place search request failed.'));
          return;
        }

        if (!results || results.length === 0) {
          observer.error(new Error('No results found for the given location and keyword.'));
          return;
        }

        const place = results[0];
        const photos = place.photos;
        if (!photos || photos.length === 0) {
          observer.error(new Error('No photos found for this place.'));
          return;
        }

        const closestPhoto = this.findClosestPhoto(photos);
        if (!closestPhoto) {
          observer.error(new Error('No photo found closest to 400px in height.'));
          return;
        }

        const imageUrl = closestPhoto.getUrl({maxHeight:282, maxWidth:1200});
        console.log(imageUrl)
        observer.next(imageUrl);
        observer.complete();
      });
    });
  }

  private findClosestPhoto(photos: any[]) {
    let closestPhoto = null;
    let minHeightDifference = Infinity;

    for (const photo of photos) {
      const photoHeight = photo.height;

      const heightDifference = Math.abs(photoHeight - 400);
      if (heightDifference < minHeightDifference) {
        closestPhoto = photo;
        minHeightDifference = heightDifference;
      }
    }
    return closestPhoto;
  }
}
