import { HttpClient } from '@angular/common/http';
import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  Signal,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { APP_STATE, AppReverseGeocodeService } from '@gth-legacy';
import { DefaultCity, Location } from '@index/interfaces';
import { Store } from '@ngrx/store';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { BehaviorSubject, map, Observable, of, switchMap, take } from 'rxjs';

import { GthUserModel } from '../../../../../../sentinels/src/lib/models/user';
import { SrvSafeWindowService } from '../../../../../../sentinels/src/lib/services/safe-window.service';

const DEFAULT_LOCATION: () => DefaultCity = () => ({
  name: 'Seattle, Wa, USA',
  lat: 47.608013,
  lng: -122.335167,
});

export const generateBoundsFromLatLng = (lat: number, lng: number, rangeInMi = 10) => {
  const earthRadiusInMeters = 6378137;
  const radiusInMeters = rangeInMi * 1609.34;
  const latDelta = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);
  const lngDelta =
    (radiusInMeters / (earthRadiusInMeters * Math.cos((Math.PI * lat) / 180))) *
    (180 / Math.PI);
  return {
    north: lat + latDelta,
    south: lat - latDelta,
    east: lng + lngDelta,
    west: lng - lngDelta,
  };
};

const DEFAULT_LAT_LNG_BOUNDS: () => google.maps.LatLngBoundsLiteral = () => ({
  north: 47.6062 + 0.1,
  south: 47.6062 - 0.1,
  east: -122.3321 + 0.125,
  west: -122.3321 - 0.125,
});

export interface LocationWithSearchBounds extends Location {
  bounds: google.maps.LatLngBoundsLiteral;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private http = inject(HttpClient);
  private defaultLocation = signal<Location>(DEFAULT_LOCATION());

  private defaultRadiusInMi = signal<number>(1000);
  private bounds = signal<google.maps.LatLngBoundsLiteral>(DEFAULT_LAT_LNG_BOUNDS());

  public getCurrentLocation(): Location {
    return this.defaultLocation();
  }

  public setRadius(newRadius: number): void {
    this.defaultRadiusInMi.set(newRadius);
  }

  public setBounds(input: google.maps.LatLngBoundsLiteral) {
    this.bounds.set(input);
  }

  public locationWithBounds = computed<LocationWithSearchBounds>(() => {
    const location = this.defaultLocation();
    return {
      name: location.name,
      lat: location.lat,
      lng: location.lng,
      bounds: this.bounds(),
    };
  });

  private reverseGeocode(lat: number, lng: number): Observable<string> {
    const url =
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
    return new Observable((observer) => {
      this.http.get<any>(url).subscribe(
        (data) => {
          const cityName =
            data.address?.city ||
            data.address?.town ||
            data.address?.village ||
            'Unknown Location';
          observer.next(cityName);
          observer.complete();
        },
        (error) => {
          console.error('Error fetching city name:', error);
          observer.next('Unknown Location');
          observer.complete();
        },
      );
    });
  }

  public setLocation(
    lat: number,
    lng: number,
    name?: string,
    bounds?: google.maps.LatLngBoundsLiteral,
  ): void {
    if (name) {
      // If name is provided, directly set the location
      this.defaultLocation.set({ name, lat, lng });
    } else {
      // If no name is provided, reverse lookup the location name
      this.reverseGeocode(lat, lng)
        .pipe(take(1))
        .subscribe((locationName) => {
          this.defaultLocation.set({ name: locationName, lat, lng });
        });
    }

    if (bounds) {
      this.bounds.set(bounds);
    } else {
      this.bounds.set(generateBoundsFromLatLng(lat, lng, 100));
    }
  }
}

function promiseToSignal<T>(promise: Promise<T>): Signal<T | undefined> {
  const result = signal<T | undefined>(undefined);

  promise
    .then((value) => result.set(value))
    .catch((error) => {
      console.error(error); // Handle error
    });

  return result;
}
