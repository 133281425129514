import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { AgeRangeValue } from '@index/interfaces';

@Component({
  selector: 'ark-age-range',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatSliderModule,
  ],
  templateUrl: './age-range.component.html',
  styleUrl: './age-range.component.scss'
})
export class ArkAgeRangeComponent {
  @Input()
  value?: AgeRangeValue;

  @Input()
  label = 'Age Range';

  @Input()
  key = '';

  @Output()
  valueChange = new EventEmitter<AgeRangeValue>();


  onSetMax(evt: any) {
    const inputElement = evt.target as HTMLInputElement;
    const val = inputElement.value;
    this.value = {
      min: this.value?.min || 0,
      max: +val,
    }
    this.onValueChange();
  }

  onSetMin(evt: any) {
    const inputElement = evt.target as HTMLInputElement;
    const val = inputElement.value;
    this.value = {
      min: +val,
      max: this.value?.max || 110,
    }
    this.onValueChange();
  }

  private onValueChange() {
    this.valueChange.emit(this.value);
  }
}
