@defer (when reviews()!== null){
  <!--Review list start-->
  <div class="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-2 lg:gap-4 xl:gap-8">
    @for (review of reviews(); track review.id) {
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-2">
          <ark-user-avatar [src]="review.user.photoURL"  />

          <div class="flex flex-col gap-y-0.5">
            <div class="font-bold">{{review.user.fullName}}</div>

            <div class="flex items-center gap-x-2">
              <div class="flex items-center gap-x-2 text-sm">
                {{review?.endorsed ? 'Endorsed' : 'Unendorsed' }}

                @if (review.endorsed) {
                  <mat-icon color="primary">check_circle</mat-icon>
                }
              </div>

              <div class="text-gray-500 text-sm">{{review.updatedAt | date: 'fullDate'}}</div>
            </div>
          </div>
        </div>

        @if (variant() === ReviewGridVariant.All) {
          <div class="text-wrap overflow-hidden text-ellipsis p-2">{{review?.comment}}</div>
        } @else {
          <div class="p-2">
            <div class="text-wrap overflow-hidden text-ellipsis line-clamp-3">{{review?.comment}}</div>
          </div>
        }
      </div>
    }
  </div>
  <!--Review list end-->
} @placeholder {
  <div class="w-100">
    <div class="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-2 lg:gap-4 xl:gap-8">
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
    </div>
  </div>
} @loading (minimum 500) {
  <div class="w-100">
    <div class="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-2 lg:gap-4 xl:gap-8">
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
      <ngx-skeleton-loader [theme]="{height: '300px', background: 'lightgray'}" />
    </div>
  </div>
}
