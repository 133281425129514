import { Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ark-button-transition',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './button-transition.component.html',
  styleUrl: './button-transition.component.scss'
})
export class ArkButtonTransitionComponent {
  icon = input.required();
  label = input.required<string>();
  color = input<string>('default'); // primary, accent, transparent, default

  onClick = output<Event>();
}
