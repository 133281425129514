import {
  AfterViewInit,
  Component,
  input,
  effect,
  OnInit,
  output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ArkUserAvatarComponent } from '@ark/components/user-avatar/user-avatar.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTooltip } from '@angular/material/tooltip';

export interface IListViewColumn<T = any> {
  header: string;
  key: string;
  sortable?: boolean;
  withImage?: boolean;
  getImage?: (item: T) => string;
  getColumnString?: (item: T) => string;
}

export interface IListViewAction<T = any> {
  icon?: string;
  label?: string;
  getTooltip?: (item: T) => string;
  getIconColor?: (item: T) => string;
  callback?: (item: T) => void;
}

export interface IActionColumn {
  headerIcon?: IconDefinition;
  headerLabel?: string;
  width?: string;
}

@Component({
  selector: 'ark-list-view',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatSortModule,
    NgTemplateOutlet,
    MatIcon,
    FaIconComponent,
    DatePipe,
    ArkUserAvatarComponent,
    MatTooltip,
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  templateUrl: './list-view.component.html',
  styleUrl: './list-view.component.scss',
})
export class ArkListViewComponent<T = any> implements OnInit, AfterViewInit {
  /**
   * A generic list of items where each item represents a row in the table
   */
  list = input.required<T[]>();
  /**
   * An array of objects that define the columns in the table.
   * - header is the display name for the column.
   * - key is the property of the generic type T used to access the data for that column.
   * - sortable is a boolean flag that determines whether the column is sortable.
   * - a function that takes an item of type T and returns a string representing the data for each column.
   */
  columns = input.required<IListViewColumn[]>();
  /**
   *  An array of objects that define the actions in the table.
   * - icon is a name of mat-icon
   * - label is a string for action button
   * - callback is a function that is triggered when click button
   */
  actions = input<IListViewAction[]>([]);
  /**
   *  An object to set configuration for action column in the table
   *  - headerIcon is font-awesome icon for action column header
   *  - headerLabel is string for action column header
   *  - width is string for action column width
   */
  actionColumn = input<IActionColumn>();
  /**
   *  A string input used to filter the rows based on column content.
   */
  filterString = input<string>();
  /**
   * The column key to sort the table by initially.
   */
  sortColumn = input<string>();
  /**
   * The column key to sort the table by initially.
   */
  sortColumnDirection = input<'asc' | 'desc'>('asc');
  /**
   * The maximum number of rows to display initially.
   * If the number of rows exceeds this amount, the table will be limited
   */
  shownAmount = input<number>(5);
  /**
   *  The customizable text for the "View All" button,
   *  which appears if the number of rows exceeds shownAmount.
   */
  viewAllButtonText = input<string>('View All');
  /**
   * The event to emit in parent component, if true, display view all button not depending on showAmount
   */
  emitViewAllOutput = input<boolean>(false);
  /**
   * The event to emit in parent component
   */
  clickViewAll = output<void>();

  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort?: MatSort;
  faAnglesRight = faAnglesRight;
  dataCount?: number;
  selectedSort?: Sort;
  filteredList: T[] = [];

  expandedElement = null;

  get showViewAllButton(): boolean {
    return (
      this.dataSource.data.length &&
      (this.dataSource.data.length < this.filteredList.length || this.emitViewAllOutput())
    );
  }

  constructor(private cdr: ChangeDetectorRef) {
    effect(() => {
      this.filteredList = this.filterString()
        ? this.list().filter((item) => {
            const values = this.columns().map((column) =>
              column.getColumnString ? column.getColumnString(item) : item[column.key],
            );
            return values.some((value) =>
              String(value).toLowerCase().includes(this.filterString().toLowerCase()),
            );
          })
        : this.list();
      this.setDataSource();
    });
  }

  ngOnInit() {
    if (this.sortColumn()) {
      this.selectedSort = {
        active: this.sortColumn(),
        direction: this.sortColumnDirection(),
      };
    }
    this.dataCount = this.shownAmount();
    this.setDisplayedColumns();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  setDataSource(): void {
    let sortedData = this.filteredList;
    if (this.selectedSort?.active && this.selectedSort?.direction) {
      sortedData = sortedData.sort((a, b) => {
        return compare(
          a[this.selectedSort.active],
          b[this.selectedSort.active],
          this.selectedSort.direction === 'asc',
        );
      });
    }
    this.dataSource.data = this.dataCount ? sortedData.slice(0, this.dataCount) : sortedData;
    this.cdr.detectChanges();
  }

  setDisplayedColumns(): void {
    const displayedColumns = this.columns().map((item) => item.key);
    if (this.actions()?.length) {
      displayedColumns.push('action');
    }
    this.displayedColumns = displayedColumns;
  }

  handleClickViewAll(): void {
    if (this.emitViewAllOutput()) {
      this.clickViewAll.emit();
    } else {
      this.dataCount = undefined;
      this.setDataSource();
    }
  }

  handleSortData(sort: Sort): void {
    this.selectedSort = sort;
    this.setDataSource();
  }
}

const compare = (a: number | string, b: number | string, isAsc: boolean) => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
