import { Component, computed, inject, model, signal } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { ReviewService } from '@sentinels/services/firebase/review.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ark-review-dialog',
  standalone: true,
  imports: [
    MatDialogClose,
    MatIcon,
    MatDialogTitle,
    MatIconButton,
    MatDialogContent,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatButton,
    MatDialogActions,
    FormsModule,
    MatFormField,
    MatFormFieldModule,
  ],
  templateUrl: './review-dialog.component.html',
  styleUrl: './review-dialog.component.scss',
})
export class ReviewDialogComponent {
  readonly snackbar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ReviewDialogComponent>);
  readonly data = inject(MAT_DIALOG_DATA);
  readonly reviewService = inject(ReviewService);

  comment = signal('');
  endorsed = signal(false);
  endorseButtonTitle = computed(() => (this.endorsed() ? 'Endorsed' : 'Endorse team!'));
  endorseButtonColor = computed(() => (this.endorsed() ? 'primary' : 'warn'));

  onClickEndorse() {
    this.endorsed.update((prev) => !prev);
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onSave(commentControl: NgModel) {
    // Validation for required field
    if (!this.comment()) {
      commentControl.control.markAsTouched();
      return;
    }

    try {
      const { teamId, user } = this.data;
      await this.reviewService.createReview({
        teamId,
        user: {
          email: user.email,
          uid: user.uid,
          fullName: user.fullName,
          photoURL: user.photoURL,
        },
        endorsed: this.endorsed(),
        comment: this.comment(),
      });
      this.dialogRef.close();

      this.snackbar.open(
        'Successfully added new review! It needs review by internal team before displaying here.',
      );
    } catch (e) {
      this.snackbar.open('Failed to add new review!');
    }
  }
}
