<div 
  class="item-type-card" 
  [ngClass]="{ disabled: disabled() }" 
  (click)="handleClickActionButton()">

  <div class="wrapper">

  <div class="image-wrapper">
    <img class="team-pic" [src]="photoURL()" alt="hero" />
  </div>

  <div class="card-content">
    <h3 class="title">{{ title() }}</h3>
    
    <div class="details">
      @if (typeIcon() || typeLabel()) {
        <span class="type">
          @if (typeIcon()) {
            <fa-icon [icon]="typeIcon()"></fa-icon>
          }
          @if (typeLabel()) {
            {{ typeLabel() }}
          }
        </span> |
      }
      <span class="member-count">
        <fa-icon [icon]="faUserGroup"></fa-icon>
        <span class="member-text">{{ memberText() }} </span>
      </span>
    </div>

    @if (location()) {
      <span class="location">
        <fa-icon [icon]="faLocationDot"></fa-icon>
        {{ location().formattedAddress }}
      </span>
    }
  </div>
</div>
</div>
