import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

export type ArkDateRangeType = 'last-7-days' | 'last-30-days' | 'last-90-days' | 'last-6-months' | 'last-year' | 'custom';

const MONTHS_ABBR = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export type ArkDateRange = {
  start: Date;
  end: Date;
}

const DATE_RANGE_TYPES: { label: string, value: ArkDateRangeType }[] = [
  {
    value: 'last-7-days',
    label: 'Last 7 days',
  },
  {
    value: 'last-30-days',
    label: 'Last 30 days',
  },
  {
    value: 'last-90-days',
    label: 'Last 90 days',
  },
  {
    value: 'last-6-months',
    label: 'Last 6 months',
  },
  {
    value: 'last-year',
    label: 'Last 365 days',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
]

@Component({
  selector: 'ark-date-range-button',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatNativeDateModule,
  ],
  templateUrl: './date-range-button.component.html',
  styleUrl: './date-range-button.component.scss'
})
export class ArkDateRangeButtonComponent implements OnInit, OnChanges {
  @Input()
  dateRangeType: ArkDateRangeType = 'last-7-days';

  @Output()
  valueChange = new EventEmitter<ArkDateRange>();

  rangeTypes = DATE_RANGE_TYPES;

  get dateRange() {
    if (!this.startDate || !this.endDate) return '';
    const startYear = this.startDate.getFullYear();
    const startMonth = MONTHS_ABBR[this.startDate.getMonth()];
    const startDay = this.startDate.getDate();
    const endYear = this.endDate.getFullYear();
    const endMonth = MONTHS_ABBR[this.endDate.getMonth()];
    const endDay = this.endDate.getDate();

    let startDateStr = `${startMonth} ${startDay}`;
    if (startYear !== endYear) {
      startDateStr += `, ${startYear}`;
    }
    const endDateStr = `${endMonth} ${endDay}, ${endYear}`;
    return `${startDateStr} - ${endDateStr}`;
  }

  startDate?: Date;
  endDate?: Date;
  dateRangeTypeLabel = 'Last 7 days';

  ngOnInit(): void {
    this.onDateRangeTypeChange(this.dateRangeType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRangeType) {
      this.onDateRangeTypeChange(this.dateRangeType);
    }
  }

  onDateChange() {
    this.dateRangeTypeLabel = 'Custom';
    this.valueChange.emit({
      start: this.startDate,
      end: this.endDate,
    });
  }

  onDateRangeTypeChange(rangeType: ArkDateRangeType) {
    const start = new Date();
    const end = new Date();
    this.dateRangeTypeLabel = this.getDateRangeTypeLabel(rangeType);
    switch (rangeType) {
      case 'last-7-days':
        start.setDate(end.getDate() - 7);
        break;
      case 'last-30-days':
        start.setDate(end.getDate() - 30);
        break;
      case 'last-90-days':
        start.setDate(end.getDate() - 90);
        break;
      case 'last-6-months':
        start.setMonth(end.getMonth() - 6);
        break;
      case 'last-year':
        start.setFullYear(end.getFullYear() - 1);
        break;
      case 'custom':
      default:
        this.dateRangeTypeLabel = 'Custom';
        start.setDate(end.getDate() - 7);
        return;
    }
    this.startDate = new Date(start);
    this.endDate = new Date(end);
    this.valueChange.emit({
      start,
      end,
    });
  }

  private getDateRangeTypeLabel(rangeType: ArkDateRangeType) {
    return this.rangeTypes.find((r) => r.value === rangeType).label;
  }
}
