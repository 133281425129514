<label for="file">
  {{label}}
  <ng-container *ngIf="tooltip">
    <mat-icon class="tooltip-icon" [matTooltip]="tooltip">info</mat-icon>
  </ng-container>
</label>

<div class="upload-btn-container">
  <button mat-flat-button color="primary" type="button" (click)="fileInput.click()"
    [disabled]="disabled || loading || !imageFormControl || imageFormControl.disabled">Upload a File</button>

  <p class="secondary-text">Maximum file size is {{maxFileSizeMB}} MB, Recommended size 400 pixels by 200 pixels.</p>

  <picture class="image-preview-container" *ngIf="imageFormControl?.value">
    <img class="image" [src]="imageFormControl.value" width="400px" height="200px" />
  </picture>
</div>

<input type="file" class="display-none" #fileInput id="file" (change)="onFileInputChange($event)"
  [accept]="accepts.join(',')" [multiple]="multiple" />
