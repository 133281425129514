import { Component, Input, input } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'ark-logo-loading',
  standalone: true,
  imports: [LottieComponent],
  templateUrl: './logo-loading.component.html',
  styleUrl: './logo-loading.component.scss',
})
export class ArkLogoLoadingComponent {
   size = input<string>('300px');
   width = input<string>(this.size());
  options: AnimationOptions = {
    path: '/assets/lottie/gth-logo-loading.json',
  };
}
