import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'ark-button-select',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './button-select.component.html',
  styleUrl: './button-select.component.scss'
})
export class ArkButtonSelectComponent {
  @Input()
  options: string[] = [];

  @Input()
  value = 'Select One';

  @Input()
  label = 'Select One';

  @Output()
  valueChange = new EventEmitter<string>();
}
