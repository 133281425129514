<div class="review-dialog p-4">
  <div mat-dialog-title class="flex">
    <div>Add a New Review</div>
    <button mat-icon-button mat-dialog-close class="absolute -top-2 right-0">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <!-- Comment and endorsement-->
  <div mat-dialog-content class="flex flex-col space-y-4 pt-2">
    <mat-form-field appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        placeholder="leave a comment..."
        [(ngModel)]="comment"
        class="w-full"
        #commentControl="ngModel"
        required>
      </textarea>
      <mat-error>Required field</mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      [color]="endorseButtonColor()"
      (click)="onClickEndorse()">
      {{endorseButtonTitle()}}

      @if (endorsed()) {
        <mat-icon iconPositionEnd>check</mat-icon>
      }
    </button>
  </div>
  <!-- Comment and endorsement-->

  <mat-dialog-actions class="flex justify-end gap-4">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button
      mat-raised-button
      cdkFocusInitial
      color="accent"
      (click)="onSave(commentControl)"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
