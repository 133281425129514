<mat-radio-group aria-label="Select an option" (change)="onRadioGroupChange($event)" [disabled]="disabled"
  [value]="value">
  @for (option of options; track option) {
  <mat-radio-button 
    [value]="option.key" 
    class="radio-button" 
    [class.--active]="value === option.key"
    [disabled]="option.disabled"
    [class.--no-description]="!option.description">
    <div class="radio-button-content">
      <p class="--label">
        {{ option.label }}
      </p>
      @if(option.description) {
      <p class="--description"> {{ option.description }}</p>
      }
    </div>
    @if(option.withIconEnd) {
    <mat-icon class="end-icon">{{ option.withIconEnd }}</mat-icon>
    }
  </mat-radio-button>
  }
</mat-radio-group>