import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressSelectionComponent } from '@gth-legacy/components/address-selection/address-selection.component';
import { Location } from '@index/interfaces';
import { GameScore, MatchEvent, TeamMatchData } from '@index/interfaces/match-entry';
import { GthUserModel } from '@sentinels/models';
import { GthMatchEntryModel } from '@sentinels/models/team-match-entry';
import { SrvApiService } from '@sentinels/services/api.service';
import { DuprService } from '@sentinels/services/dupr.service';
import { MatchEntryService } from '@sentinels/services/firebase/match-entry.service';
import { AlgoliaService } from '@shared/layouts/nav-bar-layout/components/omni-search/services/algolia.service';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

function duprIdValidator(matchEntryForm: FormGroup): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const player = control.value;

    const isPickleball = matchEntryForm?.get('activityType')?.value === 'Pickleball';
    const sendToDupr = matchEntryForm?.get('sendToDupr')?.value;

    if (!player?.hasDuprId && isPickleball && sendToDupr) {
      return { noDuprId: true };
    }
    return null;
  };
}

export function isGthUserModelValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;

    if (value && !(value instanceof GthUserModel)) {
      return { notGthUserModel: true };
    }

    return null;
  };
}

AlgoliaService;

@Component({
  selector: 'app-match-entry-form',
  templateUrl: './match-entry-form.component.html',
  styleUrls: ['./match-entry-form.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    AddressSelectionComponent,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatListModule,
  ],
})
export class MatchEntryFormComponent implements OnInit {
  matchEntryForm: FormGroup;
  gameTypes: any[];
  user = input<GthUserModel | null>(null);
  matchSource = input<string>('CLUB');
  inputMatchEntry = signal<GthMatchEntryModel | null>(null);
  duprService = inject(DuprService);
  algoliaService = inject(AlgoliaService);
  eventId = input<string>();
  fb = inject(FormBuilder);
  api = inject(SrvApiService);
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  submitEvent = output();
  matchEntryService = inject(MatchEntryService);
  currentSearch = signal<string>('');
  searchResultsArray: Observable<GthUserModel[]> = toObservable(this.currentSearch).pipe(
    switchMap((val) => {
      return this.algoliaService.search(val).pipe(
        debounceTime(300),
        startWith([]),
        map((res) => {
          const usersBucket = res.find((item) => item.name === 'Users');
          return usersBucket ? usersBucket.hits : ([] as GthUserModel[]);
        }),
      );
    }),
  );

  constructor() {
    toObservable(this.user).subscribe((val) => {
      if (!val) return;
      if (this.matchEntryForm.get('teamA').value[0] !== null) return;
      if (!this.matchEntryForm.get('addYouToTeamOne').value) return;
      this.addCurrentUserToTeamOne(val);
    });
  }

  addCurrentUserToTeamOne(user: GthUserModel) {
    const teamArr = this.matchEntryForm.get('teamA') as FormArray;
    const teamArrValues = teamArr.value as any[];
    teamArrValues.unshift(user);
    teamArrValues.pop();

    teamArr.setValue(teamArrValues);
  }

  ngOnInit() {
    this.createForm(this.inputMatchEntry());
    this.loadGameTypes();
    this.matchEntryForm.get('activityType').valueChanges.subscribe((val) => {
      if (val !== 'Pickleball') {
        this.matchEntryForm.get('sendToDupr').setValue(false);
      }
    });

    this.matchEntryForm.get('numGames').valueChanges.subscribe((numGames) => {
      this.updateScoresArray(numGames);
    });
  }

  get scores(): FormArray {
    return this.matchEntryForm.get('scores') as FormArray;
  }

  createForm(inputMatchEntry: GthMatchEntryModel | null) {
    this.matchEntryForm = this.fb.group({
      eventName: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(100)],
      ],
      eventId: [this.eventId() || null],
      location: [null, Validators.required],
      date: ['', Validators.required],
      numPlayersPerTeam: [1, [Validators.required, Validators.min(1), Validators.max(50)]],
      addYouToTeamOne: [true],
      numGames: [1, [Validators.required, Validators.min(1), Validators.max(7)]],
      sendToDupr: [false],
      activityType: [null, Validators.required],
      teamA: this.fb.array([
        new FormControl<GthUserModel | null>(this.user(), [Validators.required]),
      ]),
      teamB: this.fb.array([
        new FormControl<GthUserModel | null>(null, [Validators.required]),
      ]),
      scores: this.fb.array([
        this.fb.group({
          teamAScore: ['', Validators.required],
          teamBScore: ['', Validators.required],
        }),
      ]),
    });

    (this.matchEntryForm.get('teamA') as FormArray)
      .at(0)
      .addValidators([duprIdValidator(this.matchEntryForm), isGthUserModelValidator()]);
    (this.matchEntryForm.get('teamB') as FormArray)
      .at(0)
      .addValidators([duprIdValidator(this.matchEntryForm), isGthUserModelValidator()]);

    this.updateScoresArray(1);
  }

  getPlayersControls(team: 'teamA' | 'teamB') {
    return (this.matchEntryForm.get(team) as FormArray).controls as FormControl[];
  }

  onAddressButtonClick() {
    const address = this.matchEntryForm.get('location').value;
    const dialogRef = this.dialog.open(AddressSelectionComponent, {
      id: 'address-selection-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      maxWidth: '100%',
      width: '450px',
      data: { address },
    });
    dialogRef.afterClosed().subscribe((address: Location) => {
      this.matchEntryForm.get('location').setValue(address.formattedAddress);
    });
  }

  loadGameTypes() {
    this.api.gameTypes.listAsync().then((types) => {
      this.gameTypes = types;
    });
  }

  onSubmit() {
    const eventData = this.formdata;
    const id = this.matchEntryService.create(eventData);
    if (!id) return null;
    const data = this.duprService.addMatchScores(id, this.matchEntryForm).subscribe();

    this.snackBar.open('Match entry submitted!', 'Close');
    this.submitEvent.emit();
  }

  get formdata() {
    const eventData: MatchEvent = {
      clubId: 7614955351,
      event: this.matchEntryForm.get('eventName').value,
      eventId: this.matchEntryForm.get('eventId').value || null,
      location: this.matchEntryForm.get('location').value,
      matchDate: this.matchEntryForm.get('date').value,
      matchSource: this.matchSource() || 'CLUB',
      activity: this.matchEntryForm.get('activityType').value,
      teamA: this.generateTeamData('teamA'),
      teamB: this.generateTeamData('teamB'),
    };

    return eventData;
  }

  generateTeamData(team: 'teamA' | 'teamB'): TeamMatchData {
    const teamFormArray = this.matchEntryForm.get(team) as FormArray;
    const scoresArray = this.matchEntryForm.get('scores') as FormArray;

    const teamData: Partial<TeamMatchData> = {
      players: [],
      games: [],
    };

    teamFormArray.controls.forEach((control, index) => {
      teamData.players.push(control.value?.id || '');
    });

    scoresArray.controls.forEach((control, index) => {
      const game: Partial<GameScore> = {};
      game.gameNumber = index + 1;
      game.score = control.get(`${team}Score`)?.value || 0;

      teamData.games.push(game as GameScore);
    });

    return teamData as TeamMatchData;
  }

  removePlayer(team: 'teamA' | 'teamB', index: number): void {
    const teamControl = this.matchEntryForm.get(team) as FormArray;
    if (teamControl && index >= 0 && index < teamControl.length) {
      teamControl.removeAt(index);
    }
  }

  onPlayerCountChange(countStr: string) {
    const count = parseInt(countStr);
    console.log(count, 'here!');
    const teamA = this.matchEntryForm.get('teamA') as FormArray;
    const teamB = this.matchEntryForm.get('teamB') as FormArray;
    const diff = count - teamA.length;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        teamA.push(
          new FormControl('', [Validators.required, duprIdValidator(this.matchEntryForm)]),
        );
        teamB.push(
          new FormControl('', [Validators.required, duprIdValidator(this.matchEntryForm)]),
        );
      }
    } else {
      for (let i = 0; i < Math.abs(diff); i++) {
        teamA.removeAt(teamA.length - 1);
        teamB.removeAt(teamB.length - 1);
      }
    }
  }

  allPlayersDuprRegistered() {
    const players = this.matchEntryForm
      .get('teamA')
      .value.concat(this.matchEntryForm.get('teamB').value);
    return !!players.every((player: GthUserModel) => !!player?.hasDuprId);
  }

  onPlayerSearch(evt: any) {
    this.currentSearch.set(evt.target.value);
  }

  get createDisplayFn() {
    return (user: GthUserModel | null) => {
      if (!user) return '';
      return user.displayName;
    };
  }

  get isFormValid() {
    let isValid = true;
    const reasons = [];
    if (!this.matchEntryForm.valid) {
      isValid = false;
      reasons.push('One or more fields are not valid.');
    }

    if (!!this.matchEntryForm.get('sendToDupr')?.value && !this.allPlayersDuprRegistered()) {
      isValid = false;
      reasons.push('All players must be registered with Dupr to send to Dupr.');
    }

    return { isValid, reasons };
  }

  onActivityTypeChange(val: string) {
    if (val !== 'Pickleball') {
      this.matchEntryForm.get('sendToDupr').setValue(false);
    }
  }

  updateScoresArray(numGames: number) {
    const scoresArray = this.scores;

    // Add or remove score fields based on the number of games
    if (scoresArray.length < numGames) {
      for (let i = scoresArray.length; i < numGames; i++) {
        scoresArray.push(
          this.fb.group({
            teamAScore: ['', Validators.required],
            teamBScore: ['', Validators.required],
          }),
        );
      }
    } else if (scoresArray.length > numGames) {
      for (let i = scoresArray.length - 1; i >= numGames; i--) {
        scoresArray.removeAt(i);
      }
    }
  }

  getScoreInput(control: AbstractControl<any, any>, team: 'teamBScore' | 'teamAScore') {
    return control.get(team) as FormControl;
  }

  onAddYouToTeamOneChange(checked: boolean) {
    if (!checked) {
      const teamA = this.matchEntryForm.get('teamA') as FormArray;
      teamA
        .at(0)
        .setValue(teamA.at(0).value?.id === this.user()?.id ? null : teamA.at(0).value);
      return;
    }

    this.addCurrentUserToTeamOne(this.user());
  }
}
