<mat-card appearance="outlined" class="p-4">
  <div class="flex justify-between items-center">
    <h3>Reviews</h3>

    <button mat-raised-button color="accent" (click)="onShowReviewDialog()">
      Write a Review
    </button>
  </div>

  <mat-divider></mat-divider>

  <!--  Endorsement and sort reviews start -->
  <div class="flex justify-between items-center py-4">
    <span class="mb-5">Endorsements: {{endorsements()}}</span>

    <mat-form-field appearance="outline" class="w-25">
      <mat-label>Order by</mat-label>
      <mat-select
        [(ngModel)]="reviewOrderBy"
        (ngModelChange)="onChangeReviewOrder()"
      >
        @for (reviewOrder of reviewOrders; track reviewOrder.label) {
          <mat-option [value]="reviewOrder.value">{{reviewOrder.label}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <!--  Endorsement and sort reviews end -->

  <!--Review list start-->
  <ark-review-grid [reviews]="reviews()" [variant]="ReviewGridVariant.Partial" />
  <!--Review list end-->

  @if (!reviews().length) {
    <div class="text-center">No available reviews</div>
  }

  <!--Show all button-->
  @if (reviews().length - showCount() > 0) {
    <div class="flex mt-8">
      <button mat-raised-button color="primary" (click)="onClickShowAll()">
        Show all {{reviews().length}} reviews
      </button>
    </div>
  }
  <!--Show all button-->
</mat-card>
