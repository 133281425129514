import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ArkProgressiveFormLayoutItem, ArkProgressiveFormLayoutItemType, IArkProgressiveFormControlService } from '../types';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { ComponentPortal } from '@angular/cdk/portal';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { ArkRadioCardsComponent } from '../../radio-cards/radio-cards.component';
import { ArkAgeRangeComponent } from '../../age-range/age-range.component';
import { AgeRangeValue } from '@index/interfaces';
import { ArkCheckboxCardsComponent } from '../../checkbox-cards/checkbox-cards.component';

@Component({
  selector: 'ark-progressive-form-layout-item',
  standalone: true,
  imports: [
    MatDividerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    ArkRadioCardsComponent,
    ArkCheckboxCardsComponent,
    ArkAgeRangeComponent,
    NgxGpAutocompleteModule
  ],
  templateUrl: './progressive-form-layout-item.component.html',
  styleUrl: './progressive-form-layout-item.component.scss'
})
export class ArkProgressiveFormLayoutItemComponent implements OnInit, OnDestroy {
  @Input() control?: ArkProgressiveFormLayoutItem;
  @Input() disabled = false;
  @Input() value?: any;
  @Input() form?: IArkProgressiveFormControlService;

  @Output() valueChange = new EventEmitter<any>();

  options = {
    types: ['(cities)'],
    componentRestrictions: { country: 'US' },
  };

  selectedPortal?: ComponentPortal<any>;

  readonly dateRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  get ArkProgressiveFormLayoutItemType() {
    return ArkProgressiveFormLayoutItemType;
  }

  private subscription = new Subscription();

  ngOnInit(): void {
    if (this.control?.itemType === ArkProgressiveFormLayoutItemType.date_range) {
      this.subscription.add(
        this.dateRange.valueChanges.subscribe((val) => this.onSetValueChange(val)),
      );
    }

    if (this.control?.itemType === ArkProgressiveFormLayoutItemType.custom && this.control?.template) {
      this.selectedPortal = this.control.template as ComponentPortal<any>;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  onInputValueChange(evt: any) {
    const value = evt.target.value;
    this.onSetValueChange(value);
  }

  onSetValueChange(val: any) {
    this.value = val;
    this.onValueChange();
  }

  onAgeRangeChange(ageRange: AgeRangeValue) {
    this.value = ageRange;
    this.onValueChange();
  }

  clearCityInput() {
    if (this.form?.formGroup?.controls?.location) {
    const emptyData = { name: '', address_components: [] };
    this.form.formGroup.controls.location.setValue(emptyData);
    }
  }

  onAutocompleteSelected(place: any) {
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }
    const city = {
      name: place.formatted_address,
      lat: place.geometry!.location.lat(),
      lng: place.geometry!.location.lng(),
    };
    this.value = {
      name: city.name,
      lat: city.lat,
      lng: city.lng,
    };
    this.onValueChange()
  }
}
