<table
  mat-table
  [dataSource]="dataSource"
  class="w-full list-view-table"
  matSort
  (matSortChange)="handleSortData($event)"
  [matSortActive]="sortColumn()"
  [matSortDirection]="sortColumnDirection()"
  multiTemplateDataRows
>
  @for (column of columns(); track column.key) {
    <ng-container [matColumnDef]="column.key">
      <th
        mat-header-cell
        *matHeaderCellDef
        [mat-sort-header]="column.sortable ? column.key : undefined"
      >
        {{ column.header }}
      </th>
      <td mat-cell *matCellDef="let element" class="">
        <div class="flex items-center gap-x-2">
          @if (column.withImage) {
            <ark-user-avatar size="small" [src]="column.getImage ? column.getImage(element) : undefined" />
          }

          @if (column.getColumnString) {
            {{ column.getColumnString(element) }}
          } @else {
            {{ element[column.key] }}
          }
        </div>
      </td>
    </ng-container>
  }

  @if (actions()?.length) {
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef [style.width]="actionColumn()?.width">
        @if (actionColumn()?.headerIcon || actionColumn()?.headerLabel) {
          <span class="flex justify-center gap-2">
            @if (actionColumn().headerIcon) {
              <fa-icon [icon]="actionColumn().headerIcon"></fa-icon>
            }
            @if (actionColumn().headerLabel) {
              <span>{{ actionColumn().headerLabel }}</span>
            }
          </span>
        }
      </th>
      <td mat-cell *matCellDef="let element" class="!text-center flex gap-x-1">
        @for (action of actions(); track action) {
          @if (action.label) {
            <button
              class="action-button"
              (click)="action.callback ? action.callback(element) : undefined"
              mat-raised-button
              color="accent"
            >
              @if (action.icon) {
                <mat-icon>{{ action.icon }}</mat-icon>
              }
              {{ action.label }}
            </button>
          } @else {
            @if (action.callback) {
              <button
                class="action-button"
                mat-icon-button
                [matTooltip]="action.getTooltip ? action.getTooltip(element) : null"
                color="accent"
                (click)="action.callback(element)"
              >
                <mat-icon [color]="action?.getIconColor ? action.getIconColor(element) : ''">
                  {{ action.icon }}
                </mat-icon>
              </button>
            } @else {
              <button
                class="action-button"
                mat-icon-button
                [matTooltip]="action.getTooltip ? action.getTooltip(element) : null"
                color="accent"
                (click)="expandedElement = expandedElement === element ? null : element"
              >
                <mat-icon [color]="action?.getIconColor ? action.getIconColor(element) : ''">
                  {{ action.icon }}
                </mat-icon>
              </button>
            }
          }
        }
      </td>
    </ng-container>
  }

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="overflow-hidden flex" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
        <p>Test</p>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr class="expanded-row h-0" mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>
</table>

@if (!filteredList.length) {
  <div class="no-data-container">
    No data
  </div>
}

@if (showViewAllButton) {
  <button (click)="handleClickViewAll()" class="w-full mt-3" mat-stroked-button color="accent">
    {{ viewAllButtonText() }}
    <fa-icon [icon]="faAnglesRight"></fa-icon>
  </button>
}
