import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { APP_STATE } from '../../../../../../gth-legacy/src/public-api';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { BehaviorSubject, Observable, combineLatest, map, of, startWith, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatchEntryService } from '@sentinels/services/firebase/match-entry.service';
import { GthMatchEntryModel } from '@sentinels/models/team-match-entry';

@Injectable({
  providedIn: 'root'
})
export class MatchEntryListService {

  private store = inject(Store<APP_STATE>);
  private _eventId$ = new BehaviorSubject<string>('');
  private matchEntryService = inject(MatchEntryService);
  private user = this.store.select(selectUser);
  limit$ = new BehaviorSubject(3);
  limit = toSignal<number>(this.limit$);

  set eventId(value: string) {
    this._eventId$.next(value);
  }

  get eventId() {
    return this._eventId$.value
  }

  matchesByPlayerId$: Observable<GthMatchEntryModel[]> =  this.user.pipe(
    switchMap((user)=>{
      if(!user) return of([]);
      return this.matchEntryService.getByPlayerId(user.id);
    }),
    startWith([]),
    );

  matchesByEventId$: Observable<GthMatchEntryModel[]> =  this._eventId$.pipe(
    switchMap((eventId)=>{
      if(!eventId) return of([]);
      return this.matchEntryService.geBytEventId(eventId);
    }),
    startWith([]),
  );

  matches$ = combineLatest([this.matchesByEventId$, this.matchesByPlayerId$])
    .pipe(map(([matchesByEventId, matchesByPlayerId]) => {
      const matches = this.eventId? matchesByEventId : matchesByPlayerId;
      
      return matches.sort((a, b) => 
        a?.matchDate?.getMilliseconds() - b.matchDate?.getMilliseconds());
    }));

  limitedMatches$ = combineLatest([this.matches$, this.limit$])
    .pipe(switchMap(([matches, limit]) => of(matches.slice(0, limit))));
  limitedMatches = toSignal<GthMatchEntryModel[]>(this.limitedMatches$);

  matches = toSignal<GthMatchEntryModel[]>(this.limitedMatches$);
  canViewMore$ = combineLatest([this.matches$, this.limit$])
  .pipe(map(([matches, limit]) => matches.length <= limit));

  onShowMore() {
    this.limit$.next(this.limit$.value + 3);
  }

constructor() { }

}
