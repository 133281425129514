import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteModule,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { GthEventRatingModel, GthGameTypeModel, GthUserModel } from '@sentinels/models';
import { ParamMap } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ArkStateButtonComponent } from './components/state-button/state-button.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatRadioModule } from '@angular/material/radio';
import { TitleCasePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

export type ArkSearchBarContext =
  | 'communities'
  | 'participants'
  | 'events'
  | 'landing-page'
  | 'discover'
  | 'places';

export enum ArkSearchBarSearchType {
  PARTICIPANTS = 'participants',
  COMMUNITIES = 'communities',
  EVENTS = 'events',
  PLACES = 'places',
}

export interface ArkSearchBarFilter {
  online: boolean;
  context?: string;
  city?: {
    lat: number;
    lng: number;
    name: string;
  };
  gameType?: string[];
  ratings?: number[];
  showMap?: boolean;
  startDate?: Date;
  endDate?: Date;
  showFavorites?: boolean;
  days?: string[];
  exactDate?: Date;
  levels?: string[];
  searchText?: string;
}

export interface ArkSearchBarFilterContract {
  searchType: ArkSearchBarSearchType;
  source: 'auto' | 'manual';
  queryParams: ArkSearchBarFilter;
}

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

@Component({
  selector: 'ark-search-bar',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxGpAutocompleteModule,
    NgxMatSelectSearchModule,
    NgxSkeletonLoaderModule,
    ArkStateButtonComponent,
    MatRadioModule,
    TitleCasePipe,
    MatButtonToggleModule,
    CommonModule,
    MatTooltipModule
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class ArkSearchBarComponent implements OnInit, OnChanges {
  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  @Input()
  context?: ArkSearchBarContext;
  @Input()
  activities: GthGameTypeModel[] = [];

  @Input()
  ratings: GthEventRatingModel[] = [];

  @Input()
  mobile = false;

  @Input()
  displayOptions = false;

  @Input()
  isDiscover = false;

  @Output()
  filter = new EventEmitter<ArkSearchBarFilterContract>();

  isMobile: boolean = false;
  isMobileXs: boolean = false;

  searchTypes = Object.values(ArkSearchBarSearchType);
  types = 'cities';
  levels = ['Beginner', 'Intermediate', 'Advanced'];
  days = DAYS;
  filterDisplayed = false;
  displayedSearchType = 'Sport';
  filterOpened = true;
  filteredActivities = [];
  private _context?: ArkSearchBarContext;

  searchForm = new UntypedFormGroup({
    searchType: new UntypedFormControl(ArkSearchBarSearchType.EVENTS, Validators.required),
    gameType: new UntypedFormControl([]),
    gameFilter: new UntypedFormControl([]),
    ratings: new UntypedFormControl([]),
    startDate: new UntypedFormControl(undefined),
    endDate: new UntypedFormControl(undefined),
    showFavorites: new UntypedFormControl(false),
    online: new UntypedFormControl(false),
    days: new UntypedFormControl([]),
    levels: new UntypedFormControl([]),
    exactDate: new UntypedFormControl(undefined),
    searchText: new UntypedFormControl(''),
  });

  constructor(private breakpointObserver: BreakpointObserver, private cdr: ChangeDetectorRef,) {}

  getIcon(option: string): string {
    const iconMap = {
      participants: 'person',
      communities: 'groups',
      events: 'event',
      places: 'location_on',
    };
    return iconMap[option];
  }
  get ratingsDisplayed() {
    return this.displayedSearchType === 'Ratings';
  }

  get daysDisplayed() {
    return this.displayedSearchType === 'Days';
  }

  get exactDateDisplayed() {
    return this.displayedSearchType === 'ExactDate';
  }

  get levelsDisplayed() {
    return this.displayedSearchType === 'LevelSelect';
  }

  get isTeams() {
    return this.context === 'communities';
  }

  get isAvailablePlayers() {
    return this.context === 'participants';
  }

  get isPickupGames() {
    return this.context === 'events';
  }

  get isDiscoverReady() {
    if (this.isDiscover) {
      return this._context !== 'landing-page' && this._context !== 'discover';
    }
    return true;
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((result) => {
      this.isMobile = result.matches;
      this.isMobileXs = result.breakpoints[Breakpoints.XSmall];
      this.cdr.detectChanges();
    });

    this.filteredActivities = this.activities;

    this.searchForm.valueChanges.subscribe((searchForm) => {
      this.performSearch('auto');
      const filterText = searchForm.gameFilter;
      this.filterActivities(filterText, searchForm);
    });

    this.updateSearchType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activities) {
      this.filteredActivities = this.activities;
      this.filterActivities(
        this.searchForm.get('gameFilter')?.value,
        this.searchForm.getRawValue(),
      );
    }
  }

  /**
   * Performs a search based on the passed in param map
   * @param {ParamMap} paramMap Activated Route Param Map
   * @returns {void}
   */
  updateQueryFromParamMap(paramMap: ParamMap) {
    const params = paramMap['params'];
    let keyCount = 0;
    // eslint-disable-next-line guard-for-in
    for (const _key in params) {
      keyCount++;
    }
    if (keyCount === 0) {
      return;
    }
    const online = params['online'] === 'true';
    const context = params['context'] ?? '';
    let gameType = params['gameTypes'] ?? params['gameType'];
    /** Split game types from route */
    if (gameType && !Array.isArray(gameType)) {
      gameType = gameType.split(',');
    }
    this.searchForm.patchValue(
      {
        context,
        gameType,
        online,
      },
      { emitEvent: true },
    );
  }

  /**
   * Emits filter when search is performed
   * @param {'auto' | 'manual'} source
   * @returns {void}
   */
  performSearch(source: 'auto' | 'manual' = 'manual') {
    const value = this.searchForm.getRawValue();

    const { searchType } = value;

    const queryParams: ArkSearchBarFilter = {
      gameType: value.gameType?.map((item) => item.label) || '',
      context: value.context,
      ratings: value.ratings,
      showFavorites: value.showFavorites,
      showMap: value.showMap,
      online: value.online,
      days: value.days,
      exactDate: value.exactDate,
      levels: value.levels,
      searchText: value.searchText,
    };

    this.filter.emit({
      searchType,
      source,
      queryParams,
    });
  }

  setActivity(gameType: GthGameTypeModel) {
    this.searchForm.get('gameType').setValue(gameType.label);
  }

  onGameTypeChanged(gameType: string[]) {
    this.searchForm.get('gameType').setValue(gameType);
  }

  onOnlineChange(online: boolean) {
    this.searchForm.get('online').setValue(online);
  }

  onClearFilterButtonClick() {
    this.searchForm.setValue({
      searchType: this.getSearchType(),
      gameType: [],
      gameFilter: [],
      ratings: [],
      startDate: '',
      endDate: '',
      showFavorites: false,
      online: false,
      days: [],
      levels: [],
      exactDate: '',
      searchText: ''
    });
    this.performSearch('manual');
  }

  private getSearchType() {
    switch (this.context) {
      case 'events':
        return ArkSearchBarSearchType.EVENTS;
      case 'participants':
        return ArkSearchBarSearchType.PARTICIPANTS;
      case 'communities':
        return ArkSearchBarSearchType.COMMUNITIES;
      case 'places':
        return ArkSearchBarSearchType.PLACES;
      case 'landing-page':
      default:
        return ArkSearchBarSearchType.EVENTS;
    }
  }
  /*
  private updateSearchType() {
    switch (this.context) {
      case 'events':
        this.searchForm.get('searchType')?.setValue(ArkSearchBarSearchType.EVENTS);
        break;
      case 'participants':
        this.searchForm.get('searchType')?.setValue(ArkSearchBarSearchType.PARTICIPANTS);
        break;
      case 'communities':
        this.searchForm.get('searchType')?.setValue(ArkSearchBarSearchType.COMMUNITIES);
        break;
      case 'places':
        this.searchForm.get('searchType')?.setValue(ArkSearchBarSearchType.PLACES);
        break;
      case 'landing-page':
      default:
        break;
    }
  }
*/

  private updateSearchType() {
    const searchType = this.getSearchType();
    this.searchForm.get('searchType')?.setValue(searchType);
    this.filterActivities(
      this.searchForm.get('gameFilter')?.value,
      this.searchForm.getRawValue(),
    );
  }

  private filterActivities(filterText: string, searchForm: any) {
    if (!searchForm) return;

    const searchType = searchForm.searchType;

    if (searchType === ArkSearchBarSearchType.PLACES) {
      // Only show "Pickleball" and "-- More coming soon --" when searchType is "Places"
      this.filteredActivities = [
        ...this.activities.filter((game) => game.label.toLowerCase().includes('pickleball')),
        { id: 'more-coming-soon', label: '-- More coming soon --', disabled: true },
      ];
    } else {
      // For other contexts, perform normal filtering
      this.filteredActivities = this.activities.filter((game) => {
        return game.label.toLowerCase().includes(filterText);
      });
    }
  }
}
