<div class="message fx-row fx-gap-10x" *ngIf="message" [class.message--sending]="!message.isDelivered"
  [class.message--unread]="!isCurrentUsersMessage && !message.isRead" [class.fx-align-start]="!isCurrentUsersMessage"
  [class.fx-align-end]="isCurrentUsersMessage">

  @if(isCurrentUsersMessage) {
  @if(displayedActions?.length) {
  <button mat-icon-button [matMenuTriggerFor]="menu" class="message-actions">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @for(option of displayedActions; track option) {
    <button mat-menu-item (click)="option.action(message)">
      <mat-icon>{{ option.icon }}</mat-icon>
      <span>{{ option.label }}</span>
    </button>
    }
  </mat-menu>
  }
  <div class="message-box message--current fx-column">
    <span class="message-sent">{{
      isMessageToday ?
      (message.messageSent | date: 'shortTime') :
      (message.messageSent | date: 'medium')
      }}</span>
    <p class="message-text">
      {{ message.text }}
    </p>
  </div>
  @if(userImageSrc && !errorLoadingImage) {
  <img class="user-image" [src]="userImageSrc" [attr.aria-label]="speakerName" (error)="errorLoadingImage = true" />
  } @else {
  <mat-icon class="message--user-icon">person</mat-icon>
  }
  } @else {
  @if(userImageSrc && !errorLoadingImage) {
  <img class="user-image" [src]="userImageSrc" [attr.aria-label]="speakerName" (error)="errorLoadingImage = true" />
  } @else {
  <mat-icon class="message--user-icon">person</mat-icon>
  }

  <div class="message-box">
    <div>
      <span class="speaker-name">{{ speakerName }}</span>
      <span class="message-sent">{{
        isMessageToday ?
        (message.messageSent | date: 'shortTime') :
        (message.messageSent | date: 'medium')
        }}</span>
    </div>
    <p class="message-text">
      {{ message.text }}
    </p>
  </div>

  @if(displayedActions?.length) {
  <button mat-icon-button [matMenuTriggerFor]="menu" class="message-actions">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @for(option of displayedActions; track option) {
    <button mat-menu-item (click)="option.action(message)">
      <mat-icon>{{ option.icon }}</mat-icon>
      <span>{{ option.label }}</span>
    </button>
    }
  </mat-menu>
  }
  }
</div>