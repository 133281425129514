@if(activePage; as activePage) {
<div>
  <ark-progressive-form-layout-page
    [service]="service"
    [page]="activePage"
    [pageIndex]="pageIndex"
    [isLastPage]="isLastPage"
    [pageCount]="pageCount"
    (next)="onNextButtonClick()"
    (previous)="onBackButtonClick()"
    [formGroup]="service.formGroup"
  />
</div>
}
