import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Widget, WidgetTag } from '@index/interfaces/widgets';
import { WidgetsService, WidgetType } from '@sentinels/services/cloud/widget.service';
import { ArkDuprWidgetComponent } from '../widgets/dupr-widget/dupr-widget.component';
import { ArkScoreTrackerWidgetComponent } from '../widgets/score-tracker-widget/score-tracker-widget.component';
import { GthUserModel } from '@sentinels/models';
import { from } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../../../gth-legacy/src/public-api';
import {WIDGET_ICON_URL} from '@ark/components/widgets/data';

@Component({
  selector: 'ark-widget',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ArkDuprWidgetComponent,
    ArkScoreTrackerWidgetComponent,
  ],
  templateUrl: './widget.component.html',
  styleUrl: './widget.component.scss'
})
export class ArkWidgetComponent{
  widgetService = inject(WidgetsService);
  widget = input<Widget>();
  isSameUser = input<boolean>(true);
  edit = output<void>();
  user = input<GthUserModel>();
  types = toSignal(this.getTypes());
  dialog = inject(MatDialog);

  widgetType = signal<WidgetType | null>(null);
  isWidgetSet = computed(() => this.widget() &&  this.widgetType());

  WidgetTag = WidgetTag;

  constructor() {
    effect(async() => {
      const widget = this.widget();
      if (!widget) return;
      this.widgetType.set(this.types().find((w) => w.tag === widget.tag));

    }, {allowSignalWrites: true});
  }

  getTypes (){
    return from(this.widgetService.getWidgetTypes())
  }

  async onDelete(){
    this.dialog.open(ConfirmDialogComponent,{data:{}}).afterClosed().subscribe(async(result) => {
      if (!result) return;
        await this.widgetService.delete(this.widget().id);
    });
  }

  protected readonly WIDGET_ICON_URL = WIDGET_ICON_URL;
}
