<div class="dialog">
  <div mat-dialog-title class="dialog-title">
    <h1>{{ widget.name }}</h1>
    <button mat-icon-button mat-dialog-close (click)="onClose()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="content fx-column">
    <div class="widget-icon">
      <img [src]="WIDGET_ICON_URL.get(widget.icon)" [alt]="widget.icon + '-logo'">
    </div>
    <p class="widget-description">{{ config?.description }}</p>
    <!--Form-->
    <form class="widget-form" [formGroup]="formGroup">
      @for (configProp of properties; track configProp) {
        @if (configProp === 'activity_type') {
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="Activity Type"
              multiple
              [formControlName]="configProp"
              class="ark-sport-select ark-search fx-row"
            >
              @for (option of data.gameTypes; track option.id) {
                <mat-option [value]="option.label">{{ option.label }} </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      }
    </form>
    <div class="actions">
      @if (widget.tag === 'dupr_stat') {
        <button mat-raised-button color="accent" (click)="onSignInWithDuprButtonClick()">
          Sign In with DUPR
        </button>

        <button mat-raised-button color="primary" (click)="onHowToCreateDuprButtonClick()">
          How to create a DUPR account
        </button>
      } @else {
        <button mat-raised-button color="accent" (click)="onAddWidgetButtonClick()">
          Add Widget
        </button>
      }
    </div>
  </div>
</div>
