import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { GthMessageModel, GthUserModel } from '@sentinels/models';
import { ArkUserAvatarComponent } from '../user-avatar/user-avatar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ark-descriptive-message',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,

    ArkUserAvatarComponent,
  ],
  templateUrl: './descriptive-message.component.html',
  styleUrl: './descriptive-message.component.scss'
})
export class ArkDescriptiveMessageComponent {
  @Input()
  message?: GthMessageModel;

  @Input()
  user?: GthUserModel;

  @Input()
  speaker?: GthUserModel;

  @Input()
  speakerName = '';

  @Input()
  userImageSrc = '';

  @Input()
  isMessageToday = false;

  @Input()
  isCurrentUsersMessage = false;

  errorLoadingImage = false;
}
