@if(message) {
<mat-card class="card">
  <div class="message-info fx-row fx-gap-10x">
    <ark-user-avatar [src]="userImageSrc" />
    <div class="info-text fx-column">
      <span class="speaker-name">{{ speakerName }}</span>
      <span class="message-date">
        {{
        isMessageToday ?
        (message.messageSent | date: 'shortTime') :
        (message.messageSent | date: 'medium')
        }}
      </span>
    </div>
  </div>
  <div class="message-text">
    {{ message.text }}
  </div>
</mat-card>
}