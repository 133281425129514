<form [formGroup]="locationForm" class="address-selection">
  <h1 mat-dialog-title class="title">Add Location</h1>

  <div mat-dialog-content class="content">
    <mat-form-field class="form-field">
      <mat-label>Street Address</mat-label>
      <input #cityInput matInput required class="city-input"
             ngx-gp-autocomplete
             #placesRef="ngx-places"
             [value]="placeResult.name"
             (change)="clearCityInput()"
             (onAddressChange)="onAutocompleteSelected($event)" />

    </mat-form-field>

    @if (placeResult?.name?.length) {
      <gth-google-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
        <gth-google-map-marker [latitude]="lat"
                               [longitude]="lng"
                               [markerDraggable]="true"
                               (markerDrag)="markerDragEnd($event)" />
      </gth-google-map>
    }
  </div>

  <div class="actions" mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="onCancelButtonClick()">Cancel</button>
    <button mat-flat-button cdkFocusInitial color="primary"
            [disabled]="!isFormValid"
            (click)="onSaveButtonClick()">Save</button>
  </div>
</form>
